import { useQueryClient } from "react-query";
import { _GET, _POST } from "../../services/axios.methods";
import { API_ROUTE } from "../../utils/api";
import {
  _getQueryState,
  _useMutation,
  _useMutationV2,
} from "../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  LOCALSTORAGE,
  PROFILE_STATUS,
  RQ_KEYS,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { useManageOrganization } from "../story/common/useOrganizationDetails";

const loginApi = async ({values:payload, setEncryptedObj}: any) => {
  console.log(payload,setEncryptedObj)
  if (payload?.rememberme) {
    const encryptObj = {
      email: payload.email,
      password: payload.password,
      rememberme: payload.rememberme,
    };
    setEncryptedObj(LOCALSTORAGE.CREDS, encryptObj);
  } else {
    setEncryptedObj(LOCALSTORAGE.CREDS, {});
  }
  const data = await _POST(`${API_ROUTE.LOGIN}`, payload);
  return data;
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const { getOrganizationDetails } = useManageOrganization();
  const navigate = useNavigate();
  const loginLoadingState = _getQueryState({ key: `${RQ_KEYS.LOGIN}_loading` });
  const onSuccess = async ({ data }: any) => {
    const token = data.token;
    const auth: any = decodeToken(token);
    await localStorage.setItem(LOCALSTORAGE.TOKEN, token);
    queryClient.setQueryData(RQ_KEYS.AUTH, token);
    queryClient.setQueryData(RQ_KEYS.USER_DATA, auth);
    await getOrganizationDetails();
    if (auth.role === APP_ROLES.OBOEDIO_ADMIN) {
      return navigate(APP_PATHS.ORG_ADMIN_MANAGEMENT);
    } else if (auth.role === APP_ROLES.EXECUTIVE_USER) {
      return navigate(APP_PATHS.ORG_DASHBOARD);
    } else if (auth.role === APP_ROLES.SUPPORT_USER) {
      return navigate(APP_PATHS.ORG_DASHBOARD);
    } else if (auth.role === APP_ROLES.ORG_ADMIN) {
      if (auth.profile_status === PROFILE_STATUS.PENDING && auth.is_onbaroded !== "YES" ) {
        return navigate(APP_PATHS.ORG_ONBOARDING);
      } else {
        // return navigate(APP_PATHS.ORG_DASHBOARD);
        return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
      }
    } else if (auth.role === APP_ROLES.ORG_USER) {
      return navigate(APP_PATHS.ASSIGNED_TASKS);
    } else if (auth.role === APP_ROLES.VENDOR_USER) {
      return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
    } else if (auth.role === APP_ROLES.BRANCH_ADMIN) {
      return navigate(APP_PATHS.ASSIGNED_TASKS);
    } else if (auth.role === APP_ROLES.AUDITOR) {
      return navigate(APP_PATHS.ASSIGNED_TASKS);
    }
  };
  const { mutate: login } = _useMutationV2({
    apiCall: loginApi,
    onSuccess: onSuccess,
    showErrorToast: true,
    queryKey: RQ_KEYS.LOGIN,
  });

  return { login, loginLoadingState };
};
