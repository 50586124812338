import React from "react";
import styles from "./style.module.scss";
import loginPageImg from "../../../assests/png/loginPageImg.png";
// import loginPageImg from "../../../assests/svg/loginPageImg1.svg";
import ILogin from "../../../interfaces/ILogin";
import { Button, Checkbox, InputField } from "../../../common/components";
import { ReactComponent as OboedioIcon } from "../../../assests/svg/mainOLogo.svg";
import Link from "../../../common/components/Link";
import { Formik, Form } from "formik";
import { LoginSchema } from "./validator";
import eyeIcon from "../../../assests/svg/eye_icon.svg";
import eyeHideIcon from "../../../assests/svg/eye_hIde.svg";
import { _useMutation, _getQueryState } from "../../../services/useAxiosQuery";
import { APP_PATHS, LOCALSTORAGE, RQ_KEYS } from "../../../utils/constants";
import logoImage from "../../../assests/svg/blueLogo.svg";
import { ReactComponent as LoginEnterIcon } from "../../../assests/svg/login_enter.svg";

import { ITOAST_STATE } from "../../../interfaces/IToastState";
import Toast from "../../../common/components/Toast";
import {
  getEncryptedObj,
  setEncryptedObj,
} from "../../../utils/crypto-js/crypto-js.utils";
import { useLogin } from "../../../api/authenticator/useLogin";
const Login: React.FC<ILogin> = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { login, loginLoadingState }: any = useLogin();

  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const creds = getEncryptedObj(LOCALSTORAGE.CREDS);
  const initialLoginValues = {
    email: creds?.email || "",
    password: creds?.password || "",
    rememberme: creds?.rememberme,
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginSiteLogo}>
        <img
          style={{
            width: 150,
            alignSelf: "center",
            margin: "0.5rem 0.5rem",
          }}
          src={logoImage}
          alt="logo_image"
        ></img>
      </div>
      <div className={styles.loginFlexMain}>
        <div className={styles.loginForm}>
          <Formik
            initialValues={initialLoginValues}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              login({ values, setEncryptedObj });
            }}
          >
            {({
              handleChange,
              isValid,
              handleBlur,
              setFieldValue,
              values,
            }: any) => {
              return (
                <Form className="signup-form" style={{ height: "100%" }}>
                  <div className={styles.login}>
                    <div className={styles.loginSection1}>
                      <div className={styles.loginForm}>
                        <div className={styles.loginFormInner}>
                          <OboedioIcon
                            style={{ width: 30, alignSelf: "center" }}
                          />
                          <h1>Login</h1> <Toast toastState={toastState} />
                          {/* <p>Welcome to Oboedio</p> */}
                          <div className={styles.loginInputs}>
                            <InputField
                              name="email"
                              type="text"
                              label="Email"
                              isRequired={true}
                              display="flex"
                              flexDirection="column"
                              gap="5px"
                              fontWeight="500"
                              alignItems="flex-start"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <InputField
                              name="password"
                              type="password"
                              label="Password"
                              id="passwordLogin"
                              isRequired={true}
                              display="flex"
                              onChange={handleChange}
                              flexDirection="column"
                              gap="5px"
                              fontWeight="500"
                              alignItems="flex-start"
                              icon={showPassword ? eyeIcon : eyeHideIcon}
                              iconClass={styles.eyeIconPassword}
                              setShowPassword={setShowPassword}
                              showPassword={showPassword}
                              onBlur={handleBlur}
                              value={values.password}
                              // eyeHideIcon
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Link
                                label="Forgot Password?"
                                color="var(--secondaryColor)"
                                fontWeight="600"
                                to={APP_PATHS.FORGET_PASSWORD}
                              />
                            </div>
                          </div>
                          <div className={styles.formInnerBtnSection}>
                            <div style={{ width: "100%" }}>
                              <Checkbox
                                name="rememberme"
                                type="checkbox"
                                label={`Remember Me`}
                                isRequired={false}
                                flexDirection="row-reverse"
                                alignItems="center"
                                gap="5px"
                                onChange={handleChange}
                                checked={values.rememberme}
                                width="max-content"
                              />
                            </div>
                            <div className={styles.loginButton}>
                              <Button
                                id="loginBtn"
                                label="Log In"
                                borderRadius="5px"
                                fontWeight="bold"
                                width="60%"
                                color="var(--whiteColor)"
                                fontSize="15px"
                                border="none"
                                type="submit"
                                Icon={
                                  <LoginEnterIcon style={{ fill: "#ffffff" }} />
                                }
                                disabled={!isValid || loginLoadingState}
                                loading={loginLoadingState}
                                maxWidth="100%"
                                justifyContent="center"
                                lineHeight="1.4rem"
                                // filter="invert(100%) sepia(1%) saturate(4057%) hue-rotate(163deg) brightness(110%) contrast(100%)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className={styles.imageSection}>
          <img src={loginPageImg} alt="sample" />
        </div>
      </div>
    </div>
  );
};
export default Login;
