import { lazy } from "react";
import Success from "./OrgAdminScreens/Onboarding/Success";
import QuestionScreen from "./OrgAdminScreens/Assessments/QuestionScreen";
import QuestionSuccessScreen from "./OrgAdminScreens/Assessments/QuestionSuccessScreen";
import Dashboard from "./Dashboard/DashboardData";
import DashboardLocations from "./Dashboard/LocationList/index";
import DashboardBranchStatus from "./Dashboard/BranchCompletionData/index";
// import Executive from "./OrgAdminScreens/Dashboard/Executive";
import LocationOfficesView from "./OrgAdminScreens/Assessments/AssessmentList";
import OrgBranchLocations from "./OrgAdminScreens/ManageOrganization";
import Documents from "./OboedioScreens/Documents";
import Template from "./OboedioScreens/Templates";
import ViewAdminAssessment from "./OrgAdminScreens/Assessments/ViewAssignedAssessments";
import OrgUserTasks from "./OrgUserScreens/Tasks";
import VendorTasks from "./VendorUserScreens/Tasks";
import AnswerAssignedQuestionToOrgUser from "./OrgUserScreens/AssignAssessments";
import AnswerAssignedQuestionToVendor from "./VendorUserScreens/AssignAssessments";
import OrgUserList  from "./SupportuserScreens/Orguser/Index";
import AdminList from "./SupportuserScreens/Orgadmin/Index";
import BranchAdmin from "./SupportuserScreens/BranchAdmin/Index";
import VendorUser from "./SupportuserScreens/VendorUser/Index";
import UserLoginDetails from "./profileDetails/index";
import VendorDashboard from "./Dashboard/AssessmentDashboard";
import ManageOrganizations from "./OboedioScreens/ManageOrganizations";
// const ManageOrganizations = lazy(() => import("./OboedioScreens/ManageOrganizations"));
const EditOrganizationScreen = lazy(() => import("./OboedioScreens/ManageOrganizations/EditOrganizationDetails"));
const ViewOrganizationDetails = lazy(() => import("./OboedioScreens/ManageOrganizations/ViewOrganizationDetails"));
const ManageAssessments = lazy(() => import("./OboedioScreens/ManageAssessments"));
const Chapter = lazy(() => import("./OboedioScreens/ManageAssessments/Chapters"));
const OrgOnboarding = lazy(()=> import("./OrgAdminScreens/Onboarding"))

export {
  ManageOrganizations,
  EditOrganizationScreen,
  ViewOrganizationDetails,
  ManageAssessments,
  Documents,
  Template,
  Chapter,
  OrgOnboarding,
  Success,
  QuestionScreen,
  QuestionSuccessScreen,
  LocationOfficesView,
  OrgBranchLocations,
  ViewAdminAssessment,
  Dashboard,
  VendorDashboard,
  DashboardLocations,
  DashboardBranchStatus,
  OrgUserTasks,
  VendorTasks,
  AnswerAssignedQuestionToOrgUser,
  AnswerAssignedQuestionToVendor,
  AdminList,
  OrgUserList,
  UserLoginDetails,
  BranchAdmin,
  VendorUser,
};
