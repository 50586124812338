import React from "react";
import styles from "./style.module.scss";
import { InputField } from "../../../components";
import { Button } from "../../../components";
import { Formik, Form, ErrorMessage, Field, FieldArray } from "formik";
import cancel_icon from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ILocationModal } from "../../../../interfaces/ILocation";
import { BranchLocationInitialValue, BranchLocationSchema } from "./validator";
import { REQUEST_TYPE, RQ_KEYS } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import FormikErrorMessage from "../../../components/FormikErrorMessage";

import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  getAllCountries,
  getUSstate,
} from "../../../../utils/countriesContenent";
import { useQueryClient } from "react-query";
const AddEditLocationModal: React.FC<ILocationModal> = ({
  onClickCancel,
  heading,
  type,
  data,
  org_id,
}) => {
  // const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  // console.log("AddEditLocationModal", data);
  // //   const { assessment_id } = useParams();
  // const queryClient = useQueryClient();

  // const getUserOrgMutate = _useMutation({
  //   queryKey: RQ_KEYS.USER_ORGANIZATION,
  // });
  // const onSuccess = async () => {
  //   await getUserOrgMutate.mutate({
  //     url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
  //     requestType: REQUEST_TYPE._GET,
  //   });
  //   onClickCancel();
  // };
  // //   const addArticleMutate = _useMutation({ onSuccess });
  // const locationMutate = _useMutation({ onSuccess });
  const regionList = useApiQuery({
    queryKey: RQ_KEYS.REGIONS,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_REGIONS,
  });
  // const loading = _getQueryState({ key: RQ_KEYS.LOADING });
  // const onSubmit = () => {
  //   console.log("values",formik.values);
  // };
  return (
    <div className={styles.invitePopupContainer}>
      <div className={styles.invitePopupContainerInner}>
        <div className={styles.addChapterHeader}>
          <p>{heading}</p>
          <img
            className="svg_image_icon"
            src={cancel_icon}
            alt="invite"
            onClick={onClickCancel}
          ></img>
        </div>
        <Formik
          initialValues={type === "CREATE" ? BranchLocationInitialValue : data}
          validationSchema={BranchLocationSchema}
          enableReinitialize={true}
          onSubmit={async (values) => {
            console.log("values", values);
            // queryClient.setQueryData(RQ_KEYS.LOADING, true);
            // await locationMutate.mutate({
            //   url: API_ROUTE.CREATE_UPDATE_LOCATION,
            //   requestType: REQUEST_TYPE._PATCH,
            //   requestData: {
            //     ...values,
            //     org_id,
            //   },
            // });
          }}
        >
          {({ handleChange, isValid, values, setFieldValue }) => (
            <div className={styles.invitePopupRow2}>
              <Form>
                <div className={styles.userLogin}>
                  <div className={`${styles.userLoginInner} ${styles.full}`}>
                    <InputField
                      name="branch_name"
                      alignItems="left"
                      type="text"
                      label="Office Location"
                      placeholder="Enter Office Location"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="5px"
                      onChange={handleChange}
                      value={values?.branch_name}
                    />
                  </div>
                  <div className={`${styles.userLoginInner} ${styles.full}`}>
                    <InputField
                      name="street_address_1"
                      alignItems="left"
                      type="text"
                      label="Office Address"
                      placeholder="Enter Office Address"
                      isRequired={true}
                      display="flex"
                      fontWeight="600"
                      fontSize="12px"
                      gap="5px"
                      onChange={handleChange}
                      value={values?.street_address_1}
                    />
                  </div>

                  <div
                    className={`${styles.userLoginInner} ${styles.fullInner}`}
                  >
                    <div className={styles.orgRegionsParent}>
                      <div className={styles.orgRegionsHeading}>
                        <h3>Location</h3>
                      </div>

                      <div className={styles.orgRegions}>
                        <div className={styles.editField}>
                          <label>
                            Region
                            <sup>*</sup>
                          </label>
                          <Field
                            as="select"
                            name={`region`}
                            value={`${values?.region}`}
                            onChange={(e: any) => {
                              setFieldValue(`country`, "");
                              handleChange(e);
                            }}
                          >
                            <option value={""}>Select Region</option>
                            {regionList &&
                              regionList?.data &&
                              regionList?.data.length > 0 &&
                              regionList?.data.map((reg: any) => (
                                <option key={reg.value} value={reg.value}>
                                  {reg.value}
                                </option>
                              ))}
                          </Field>

                          <ErrorMessage component="p" name={`region`} />
                        </div>
                        {values?.region &&
                          values?.region !== "Online (worldwide)" && (
                            <div className={styles.editField}>
                              <label>
                                Country
                                <sup style={{ color: "var(--redColor)" }}>
                                  *
                                </sup>
                              </label>

                              <Field
                                as="select"
                                name={`country`}
                                value={`${values?.country}`}
                                onChange={(e: any) => {
                                  setFieldValue(`state`, "");
                                  handleChange(e);
                                }}
                              >
                                <option value={""}>Select Country</option>
                                {getAllCountries(values?.region).map(
                                  (cou: any) => (
                                    <option key={cou.name} value={cou.name}>
                                      {cou.name}
                                    </option>
                                  )
                                )}
                              </Field>
                              <p
                                style={{
                                  color: "red",
                                  width: "100%",
                                  fontWeight: "normal",
                                  fontSize: "12px",
                                }}
                              >
                                <ErrorMessage name={`country`} component="p" />
                              </p>
                            </div>
                          )}

                        {values?.country === "United States" && (
                          <div className={styles.editField}>
                            <label>State(Optional)</label>
                            <Field
                              as="select"
                              name={`state`}
                              onChange={handleChange}
                              value={`${values.state}`}
                            >
                              <option value={""}>Select State</option>
                              {(() => {
                                return getUSstate().map((state: any) => (
                                  <option key={state.name} value={state.name}>
                                    {state.name}
                                  </option>
                                ));
                              })()}
                            </Field>
                            <FormikErrorMessage name={`state`} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.inviteUserBtn}>
                  <Button
                    label="Cancel"
                    id="cancelbtn"
                    type="button"
                    borderRadius="5px"
                    fontWeight="bold"
                    fontSize="var(--secondaryFont)"
                    background="var(--whiteColor)"
                    border="1px solid var(--secondaryColor)"
                    color="var(--secondaryColor)"
                    Icon={
                      <CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />
                    }
                    onClick={onClickCancel}
                  />
                  <Button
                    label={
                      type === "CREATE"
                        ? "Save Location"
                        : "Update Office Location"
                    }
                    id="saveAssessmentbtn"
                    borderRadius="5px"
                    fontWeight="bold"
                    color="var(--whiteColor)"
                    fontSize="var(--secondaryFont)"
                    border="none"
                    type="submit"
                    Icon={
                      <EditIcon fill="#ffffff" className="svg_image_icon" />
                    }
                    disabled={!isValid}
                  />
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddEditLocationModal;
