import React, { useState } from "react";
import { IButton } from "../../interfaces/IButton";
import styles from "./button.module.scss";
import SvgIcon from "../SvgIcon";
import { Spinner } from "react-bootstrap";
const Button: React.FC<IButton> = ({
  type,
  width,
  maxWidth,
  background,
  borderRadius,
  border,
  padding,
  fontWeight,
  fontSize,
  opacity,
  lineHeight,
  color,
  label,
  alignItems,
  onClick,
  marginRight,
  textAlign,
  secondary,
  disabled,
  styleOverride,
  id,
  Icon,
  btnClass,
  gap,
  flexDirection,
  display,
  loading,
  borderColor,
  justifyContent,
  filter,
  textDecoration,
  title,
}) => {
  const buttonStyle = {
    width: width || "100%",
    textDecoration: textDecoration || "none",
    display: display || "flex",
    justifyContent: justifyContent || "center",
    maxWidth: maxWidth || "fit-content",
    background: disabled
      ? "var(--whiteColor)"
      : background
      ? background
      : secondary
      ? "var(--whiteColor)"
      : "var(--secondaryColor)",
    borderRadius: borderRadius || "5px",
    opacity: disabled ? "1" : "1",
    // opacity: opacity || "1",
    border: disabled
      ? "1px solid var(--secondaryColor)"
      : border
      ? border
      : secondary
      ? "1px solid var(--secondaryColor)"
      : "none",
    lineHeight: lineHeight || "inherit",
    padding: padding || "6px 10px",
    fontWeight: fontWeight || "600",
    fontSize: fontSize,
    color: disabled
      ? "var(--secondaryColor)"
      : color
      ? color
      : secondary
      ? "var(--secondaryColor)"
      : "var(--whiteColor)",
    marginRight: marginRight || "0px",
    textAlign: textAlign || "center",
    alignItems: alignItems || "center",
    gap: gap,
    // display: display || "block",
    flexDirection: flexDirection || "row",
    cursor: disabled ? "not-allowed" : "pointer",
    "&:hover": {
      backgroundColor: background
        ? background
        : secondary
        ? "var(--whiteColor)"
        : "#0097cea1",
    },
  };
  const loader = {
    top: "50%",
    right: "-1px",
    transform: "translate(-50%, -50%)",
    width: "12px",
    height: "12px",
    border: "2px solid",
    borderColor: borderColor ? borderColor : "#fff",
    borderTopColor: "transparent",
    borderRadius: "50%",
    animation: "spin 1s infinite linear",
  };
  // filter = filter`
  //   ? filter
  //   : `invert(0%) sepia(4%) saturate(0%) hue-rotate(337deg) brightness(100%) contrast(107%)`;`

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      id={id}
      disabled={disabled}
      type={type}
      style={{ ...buttonStyle, ...styleOverride, gap: Icon ? "5px" : "0px" }}
      onClick={handleClick}
      className={`${btnClass} ${styles.button}`}
      title={title}
    >
      {/* <Logo style={{ fill: "#ffffff" }} /> */}
      {/* {Icon && (
        <img
          style={{ color: "#000000" }}
          className="svg_image_icon"
          src={Icon}
          alt="Icon"
        ></img>
      )} */}
      <span
        className={`svg_image_icon ${disabled ? styles.disabledIcon : null}`}
      >
        {Icon}
      </span>
      {label}
      {loading && <Spinner animation="border" variant="primary" style={{width: 12, height:12}} /> 
      
      // <span style={{ ...loader, position: "absolute" }}></span>
      }
      {/* <span style={{ ...loader, position: "absolute" }}></span> */}
    </button>
  );
};

export default Button;
