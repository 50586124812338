// import React from "react";
// import { format } from 'date-fns';
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   Image,
// } from "@react-pdf/renderer";

// const Pdf = ({ organisationName, assessmentData }: any) => {
//   // Group the assessment data by chapter_id

//   console.log(">>>> assessmentData",assessmentData);

//   const groupedData = assessmentData?.reduce((acc: any, curr: any) => {
//     // Use chapter_id as the key
//     if (!acc[curr.chapter_id]) {
//       acc[curr.chapter_id] = {
//         chapter_name: curr.chapter_name,
//         questions: [],
//       };
//     }
//     // Push the current question into the relevant chapter group
//     acc[curr.chapter_id].questions.push(curr);
//     return acc;
//   }, {});

//   const formatDate = (dateString: any) => {
//     if (!dateString || dateString === "0000-00-00") return "N/A";
//     const date = new Date(dateString);

//     if (isNaN(date.getTime())) return "N/A";

//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     const year = date.getFullYear();

//     return `${month}-${day}-${year}`; // Format as month-date-year
//   };

//   return (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <View style={styles.mainContainer}>
//           <View style={styles.header}>
//             <Image style={styles.logoImg} src="/logo.png" />
//             <Text style={styles.headerText}>{organisationName}</Text>
//           </View>
//           <Text style={styles.mainHeading}>
//             {assessmentData[0]?.assessment_name}
//           </Text>
//           {Object.keys(groupedData).map((chapterId) => {
//             const chapter = groupedData[chapterId];

//             return (
//               <View key={chapterId}>
//                 {/* Render chapter name only once */}
//                 <Text style={styles.chapterName}>
//                   Chapter Name: {chapter.chapter_name}
//                 </Text>

//                 {chapter.questions.map((data: any, index: any) => (
//                   <View style={styles.section} key={index}>
//                     {/* <View style={styles.detailView}>
//                       <View style={styles.innerStyle}>
//                         <Text>Assigned To: {data.assigned_to_username}</Text>
//                         <Text>
//                           Assigned Date: {formatDate(data.assigned_at)}
//                         </Text>
//                         <Text>Completion Status: {data.assigned_status}</Text>
//                       </View>
//                       <View style={styles.innerStyle}>
//                         <Text>Assigned By: {data.assigned_by_username}</Text>
//                         <Text style={styles.margin}>
//                           Due Date: {formatDate(data.due_date)}
//                         </Text>
//                       </View>
//                     </View> */}
//                     <Text style={styles.questionData}>
//                       Q{index + 1}: {data.question}
//                     </Text>

//                     {data.question_type === "Subjective" && (
//                       <Text style={styles.questionData}>
//                         A: {data.answer_subjective || "N/A"}
//                       </Text>
//                     )}

//                     {data.question_type === "Objective" && (
//                       <Text style={styles.questionData}>
//                           A: {data?.option_text || "N/A"}
//                       </Text>
//                     )}

//                     {data.question_type === "Multi-Option Selection" && (
//                       <Text style={styles.questionData}>
//                         A:{" "}
//                         {Array.isArray(data.multi_option_answers) && data.multi_option_answers.length > 0
//                           ? data.multi_option_answers.map((answer:any, answerIndex:any) => (
//                             <Text key={answerIndex}>{`${answerIndex + 1}. ${answer.option_text}${answerIndex < data.multi_option_answers.length - 1 ? ', ' : ''}`}</Text>
//                           ))
//                           : "N/A"}
//                       </Text>
//                     )}

//                     {data.question_type === "LIST_PROCESS" && (
//                       <Text style={styles.questionData}>
//                         A: {data?.option_text || "N/A"}
//                       </Text>
//                     )}

//                       {data.question_type === "List" && (
//                       <Text style={styles.questionData}>
//                         A:{" "}
//                         {data.answer_list &&
//                         data.answer_list.length > 0
//                           ? data.answer_list.map(
//                               (answer: any, answerIndex: any) => (
//                                 <Text key={answerIndex}>{` ${answer}`}</Text>
//                               )
//                             )
//                           : "N/A"}
//                       </Text>
//                     )}
//                   </View>
//                 ))}
//               </View>
//             );
//           })}
//         </View>
//       </Page>
//     </Document>
//   );
// };

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     backgroundColor: "#f1f2f4",
//     color: "#270089",
//     padding: 10,
//   },
//   mainContainer: {
//     padding: 10,
//     border: 1,
//     borderColor: "#dfe2eb",
//   },
//   header: {
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//   },
//   headerText: {
//     fontSize: 14,
//   },
//   logoImg: {
//     width: 160,
//     height: 19,
//   },
//   mainHeading: {
//     marginVertical: 10,
//     textAlign: "center",
//     fontWeight: "bold",
//   },
//   logo: {
//     height: 50,
//     width: 100,
//   },
//   section: {
//     margin: 5,
//     padding: 5,

//     //   flexGrow: 1,
//   },
//   text: {
//     fontSize: 12,
//   },
//   margin: {
//     marginBottom: 5,
//   },
//   chapterName: {
//     fontSize: 14,
//   },
//   detailView: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     color: "gray",
//     fontSize: 12,
//     marginBottom: 8,
//   },
//   innerStyle: {
//     display: "flex",
//     gap: 3,
//   },
//   questionData: {
//     fontSize: 12,
//     color: "black",
//   },
// });

// export default Pdf;


import React from "react";
import { format } from 'date-fns';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";


const Pdf = ({ organisationName, assessmentData }: any) => {
  console.log(">>>> assessmentData", assessmentData);

  const formatDate = (dateString: any) => {
    if (!dateString || dateString === "0000-00-00") return "N/A";
    const date = new Date(dateString);

    if (isNaN(date.getTime())) return "N/A";

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`; // Format as month-date-year
  };

  const renderTable = (tableData:any, excludedKeys:any = []) => {
    if (!tableData || tableData.length === 0) return <Text>No data available</Text>;
  
    // Parse JSON string if tableData is coming as a string
    const parsedTableData =
      typeof tableData === "string" ? JSON.parse(tableData) : tableData;
  
    // Get all keys excluding the ones in excludedKeys
    const headers = Object.keys(parsedTableData[0]).filter(
      (key) => !excludedKeys.includes(key)
    );
  
    return (
      <View style={styles.table}>
        {/* Render table header */}
        <View style={styles.tableRow}>
          {headers.map((header, index) => (
            <Text key={index} style={styles.tableHeader}>
              {header.replace(/_/g, " ")}
            </Text>
          ))}
        </View>
  
        {/* Render table rows */}
        {parsedTableData.map((row:any, rowIndex:any) => (
          <View key={rowIndex} style={styles.tableRow}>
            {headers.map((header, colIndex) => (
              <Text key={colIndex} style={styles.tableCell}>
                {row[header] || "N/A"}
              </Text>
            ))}
          </View>
        ))}
      </View>
    );
  };
  
  
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.header}>
            <Image style={styles.logoImg} src="/logo.png" />
            <Text style={styles.headerText}>{organisationName}</Text>
          </View>
          <Text style={styles.mainHeading}>
            {assessmentData?.assessment_name}
          </Text>
          {assessmentData?.chapters?.map((chapter: any) => (
            <View key={chapter.chapter_id}>
              <Text style={styles.chapterName}>
                Chapter Name: {chapter.chapter_name}
              </Text>

              {chapter?.articles?.map((article: any) => (
                <View key={article.article_id}>
                  <Text style={styles.articleName}>
                    Section: {article.article_name}
                  </Text>

                  {article.article_question_type === "QnA" &&
                    article?.questions?.map((data: any, index: any) => (
                      <View style={styles.section} key={index}>
                        <Text style={styles.questionData}>
                          Q{index + 1}: {data.question}
                        </Text>

                        {data.question_type === "Subjective" && (
                          <Text style={styles.questionData}>
                            A: {data.answerSubjective || "N/A"}
                          </Text>
                        )}

                        {data.question_type === "Objective" && (
                          <Text style={styles.questionData}>
                            A: {data?.answerObjective || "N/A"}
                          </Text>
                        )}

                        {data.question_type === "Multi-Option Selection" && (
                          <Text style={styles.questionData}>
                            A:{" "}
                            {Array.isArray(data.multiOptionAnswer) &&
                            data.multiOptionAnswer.length > 0
                              ? data.multiOptionAnswer.map(
                                  (answer: any, answerIndex: any) => (
                                    <Text key={answerIndex}>
                                      {`${answerIndex + 1}. ${answer}${
                                        answerIndex <
                                        data.multiOptionAnswer.length - 1
                                          ? ", "
                                          : ""
                                      }`}
                                    </Text>
                                  )
                                )
                              : "N/A"}
                          </Text>
                        )}
                      </View>
                    ))}

                  {/* Handling TABLE_TYPE_ADD_ROW Articles */}
                  {article.article_question_type === "TABLE_TYPE_ADD_ROW" && (
                    <>
                      {/* Handle List Process Table */}
                      {article.isListProcess && article?.listProcessData && (
                        <>
                          {/* <Text style={styles.articleName}>List Process Data:</Text> */}
                          {renderTable(article.listProcessData, [
                            { key: "process_name", label: "Process Name" },
                            { key: "process_description", label: "Process Description" },
                            { key: "no_of_employees", label: "No of Employees" },
                          ])}
                        </>
                      )}

                      {/* Handle Standard Table Data */}
                      {!article.isListProcess && article?.tableData && (
                        <>
                        {renderTable(article.tableData, ["id", "process_name"])}
                          {/* <Text style={styles.articleName}>Table Data:</Text> */}
                          {/* {renderTable(
                            article.tableData,
                            Object.keys(JSON.parse(article.tableData)[0]).map((key) => ({
                              key,
                              label: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
                            }))
                          )} */}
                        </>
                      )}
                    </>
                  )}

                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#f1f2f4",
    color: "#270089",
    padding: 10,
  },
  mainContainer: {
    padding: 10,
    border: 1,
    borderColor: "#dfe2eb",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 14,
  },
  logoImg: {
    width: 160,
    height: 19,
  },
  mainHeading: {
    marginVertical: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  logo: {
    height: 50,
    width: 100,
  },
  section: {
    margin: 5,
    padding: 5,
  },
  chapterName: {
    fontSize: 14,
    marginBottom:5,
    marginTop:10,
  },
  articleName: {
    fontSize: 12,
    fontWeight: "bold",
  },
  questionData: {
    fontSize: 12,
    color: "black",
  },
  // Table Styles
  table: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    border: 1,
    borderColor: "#dfe2eb",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#dfe2eb",
  },
  tableHeader: {
    width: "33%",
    fontSize: 10,
    textAlign: "center",
    padding: 5,
    fontWeight: "bold",
    textTransform:"uppercase",
    flex: 1,
    borderLeftWidth: 1,
    borderColor: "#000", 
    backgroundColor: "#f0f0f0", 
  },
  tableCell: {
    width: "33%",
    fontSize: 10,
    textAlign: "center",
    padding: 5,
    flex: 1,
    borderLeftWidth: 1,
    borderColor: "#000",
    color: "black",
  },
});

export default Pdf;
