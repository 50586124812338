import React, { memo, useEffect, useMemo, useState } from "react";
import { InviteUserPopup } from "../../../../common/Parts";
import styles from "./style.module.scss";
import { Pagination } from "../../../../common/components/Pagination";
import {
  Button,
  Dropdown,
  SearchBar,
  Table,
} from "../../../../common/components";
import {
  APP_PATHS,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { ReactComponent as InviteUserIcon } from "../../../../assests/svg/invite_user.svg";
import { ReactComponent as CloseIcon } from "../../../../assests/svg/close.svg";
import { useQueryClient } from "react-query";
import useDebounce from "../../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ViewIcon } from "../../../../assests/svg/view_icon.svg";
import Spinner from "react-bootstrap/Spinner";
import { addMinutes } from "../../../../utils/helpers";
import useTimer, { ITimer } from "../../../../hooks/useTimer";
import { useManageOrganization } from "../../../../api/story/oboedioScreens/useManageOrganizations";
const ManageOrganizations: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = React.useState<string>("null");
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  
  // const [organizationUsersData, setOrganizationUsersData] = React.useState<any>();

  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  // const {
  //   getOrgAdminLists,
  //   orgAdminLists,
  //   changeUserActiveStatusApi,
  //   getAdminListLoadingState,
  //   resentInvitationApi,
  // }: any = useManageOrganization();

  const { timer, setTimer } = useTimer();

  let { data, isLoading } = useApiQuery({
    queryKey: RQ_KEYS.ORG_ADMINS_LIST,
    requestType: REQUEST_TYPE._GET,
    url:
      API_ROUTE.GET_ORG_ADMINS_LIST +
      `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
  });
  let totalCount = data && data.totalCount;
  data = data && data.userData;

  // let totalCount = orgAdminLists && orgAdminLists.totalCount;
  // useEffect(() => {
  //   if (orgAdminLists) {
  //     setOrganizationUsersData(orgAdminLists?.userData)
  //   }
  // }, [orgAdminLists]);

  const getOrgAdminLists = _useMutation({ queryKey: RQ_KEYS.ORG_ADMINS_LIST });
  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_ADMINS_LIST });
  };

  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getOrgAdminLists.mutate({
      url: API_ROUTE.GET_ORG_ADMINS_LIST + `/${LIMIT}/${offset}/null`,
      requestType: REQUEST_TYPE._GET,
    });
    // getOrgAdminLists({ params: `/${LIMIT}/${offset}/null` });
  };

  const { mutate } = _useMutation({
    onSuccess,
  });

  const changeUserActiveStatus = _useMutation({ onSuccess });

  const changeActiveStatus = async ({ action, org_id }: any) => {
    console.log("changeActiveStatus inside ",action,org_id)
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    await changeUserActiveStatus.mutate({
      url: API_ROUTE.ACTIVE_SUSPEND_ORGNIZATION,
      requestType: REQUEST_TYPE._PATCH,
      requestData: { action, org_id },
    });
    // await changeUserActiveStatusApi({ action, org_id });
  };
  const searchQuery = useDebounce(searchKey, 2000);
  React.useMemo(() => {
    if (searchQuery || searchKey.length < 0) {
      getOrgAdminLists.mutate({
        url:
          API_ROUTE.GET_ORG_ADMINS_LIST +
          `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
        requestType: REQUEST_TYPE._GET,
      });
      // getOrgAdminLists({
      //   params: `/${dataLimit.limit}/${dataLimit.offset}/${searchKey}`,
      // });
    } else if (searchKey.length === 0) {
      getOrgAdminLists.mutate({
        url:
          API_ROUTE.GET_ORG_ADMINS_LIST +
          `/${dataLimit.limit}/${dataLimit.offset}/null`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  }, [searchQuery]);

  const handleResendInvitation = (item: any) => {
    queryClient.setQueryData(RQ_KEYS.LOADING, true);
    mutate({
      url: API_ROUTE.RESEND_ORG_ADMIN_INVITE,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        email: item.email,
        org_name: item.org_name,
        org_id: item?.org_id,
        role: item?.role,
        first_name: item?.first_name,
        last_name: item?.last_name,
      },
  })
    
    // resentInvitationApi({
    //   email: item.email,
    //   org_name: item.org_name,
    //   org_id: item?.org_id,
    //   role: item?.role,
    //   first_name: item?.first_name,
    //   last_name: item?.last_name,
    // });
    const newDate = addMinutes(new Date(), 1);
    const newTimer = [
      ...timer,
      { userId: item.user_id, timeLeft: 60, endTime: newDate },
    ];

    localStorage.setItem("timer", JSON.stringify(newTimer));
    setTimer(newTimer);
  };

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  console.log("🚀 ~ organizationData:", organizationData);

  return (
    <React.Fragment>
      <div className={styles.userManagementContainer}>
        <div className={styles.userManagementBody}>
          <div className={styles.userDataRowOne}>
            <SearchBar
              type="text"
              placeholder="Search"
              isRequired={false}
              name="searchBar"
              display="flex"
              onChange={(event: any) => setSearchKey(event.target.value)}
              Icon={<CloseIcon fill="#000" />}
            />
            <Button
              label="Invite Organization"
              id="invite user btn"
              borderRadius="5px"
              padding="6px 8px"
              fontWeight="bold"
              color="var(--whiteColor)"
              fontSize="var(--secondaryFont)"
              onClick={() => {
                setShowPopup(!showPopup);
              }}
              border="none"
              Icon={<InviteUserIcon />}
            />
          </div>
          <div className={styles.userDataRowTable}>
            {data && data.length > 0 ? (
              <Table
                tableHeaders={[
                  "Org. ID",
                  "Organization Name",
                  "Region",
                  "Location",
                  "Industries",
                  "Org. Admin Email",
                  "Org. Status",
                  "Users",
                  "Assessments",
                ]}
                tableData={data?.map((item: any) => {
                        return {
                          ...item,
                          location:
                            item.location && item.location !== "null"
                              ? JSON.parse(item.location).join(", ")
                              : "N/A",
                          org_industries:
                            item.org_industries &&
                            item.org_industries !== "null"
                              ? JSON.parse(item.org_industries).join(", ")
                              : "N/A",
                          org_region:
                            item.org_region && item.org_region !== "null"
                              ? JSON.parse(item.org_region).join(", ")
                              : "N/A",
                          assessments:
                            item.assessments === 0 ? "0" : item.assessments,
                        };
                      })
                }
                hideColumns={["user_id", "is_active"]}
                showActionWithColumns={[
                  {
                    key: "org_status",
                    button: (data: any) => {
                      if (
                        data &&
                        (data.org_status === "PENDING" ||
                          data.org_status === "")
                      ) {
                        return (
                          <td>
                            <span className={styles.invitePending}>
                              Invite Pending
                            </span>
                            <Button
                              label="Resend Invitation"
                              id="resendInvitation"
                              border="1px solid var(--secondaryColor)"
                              fontWeight="500"
                              padding="5.5px 10px"
                              maxWidth="100%"
                              width="140px"
                              fontSize="var(--secondaryFont)"
                              background="var(--whiteColor)"
                              lineHeight="inherit"
                              color="var(--secondaryColor)"
                              // Icon={<SendInviteIcon  style={{ maxWidth: "12px" }} />}
                              onClick={() => handleResendInvitation(data)}
                              disabled={(() => {
                                const timerState = timer.find(
                                  (state: ITimer) =>
                                    state.userId === data.user_id
                                );
                                return timerState && timerState.timeLeft > 0;
                              })()}
                            />
                            <span className={styles.timerSpan}>
                              {(() => {
                                const timerState = timer.find(
                                  (state: ITimer) =>
                                    state.userId === data.user_id
                                );
                                if (timerState) {
                                  return `0:${timerState.timeLeft}`;
                                } else return "";
                              })()}
                            </span>
                          </td>
                        );
                      } else {
                        return (
                          <Dropdown
                            label={data?.org_status}
                            classParent={styles.dropdownUl}
                            dropdownData={[
                              {
                                name: "ACTIVE",
                                value: "ACTIVE",
                              },
                              {
                                name: "SUSPENDED",
                                value: "SUSPENDED",
                              },
                            ]}
                            onClick={(selectedItem: any) => {
                              console.log("selected items",selectedItem,data)
                              changeActiveStatus({
                                action:
                                  selectedItem.value,
                                org_id:
                                  data?.org_id
                              }
                              );
                            }}
                          />
                        );
                      }
                    },
                  },
                ]}
                action={{
                  name: "Action",
                  buttonOne: (data: any) => {
                    return (
                      <Button
                        onClick={() => {
                          navigate(
                            `${APP_PATHS.VIEW_ORGANIZATION}/${data.org_id}`
                          );
                        }}
                        label="View"
                        id="view"
                        Icon={
                          <ViewIcon
                            fill="#ffffff"
                            className={`svg_image_icon ${styles.viewIcon}`}
                          />
                        }
                      // fill="#000000"
                      // className="svg_image_icon"
                      />
                    );
                  },
                }}
              />
            ) : (isLoading ? (
              <div className={styles.orgLoader}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <h6 style={{ paddingTop: "1rem", textAlign: "center" }}>
                No Organization Data Found
              </h6>
            ))}
            {/* <Pagination
              data={organizationUsersData}
              totalCount={totalCount}
              onPaginzationChange={onPaginzationChange}
              setDataLimit={setDataLimit}
              dataLimit={dataLimit}
            /> */}
            <Pagination
            data={data}
            totalCount={totalCount}
            onPaginzationChange={onPaginzationChange}
            setDataLimit={setDataLimit}
            dataLimit={dataLimit}
          />
          </div>
        </div>
      </div>
      <InviteUserPopup
        openState={showPopup}
        heading="Invite Organization Admin"
        onClickCancel={() => {
          setShowPopup(false);
        }}
      />
    </React.Fragment>
  );
};
export default ManageOrganizations;
