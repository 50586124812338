import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  APP_PATHS,
  APP_ROLES,
  LOCALSTORAGE,
  PROFILE_STATUS,
} from "../utils/constants";
import { decodeToken } from "react-jwt";
const ProtectedRoute = ({ children, authAccess, allowedRoles }: any) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [auth, setAuthData] = useState<any>(null);
  const userToken = localStorage.getItem(LOCALSTORAGE.TOKEN) as string;
  const checkUserToken = () => {
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate(APP_PATHS.LOGIN);
    }
    setAuthData(decodeToken(userToken));
    setIsLoggedIn(true);
  };
  useMemo(() => {
    checkUserToken();
  }, [isLoggedIn, userToken]);

  const renderProtectedRoutes = React.useCallback(() => {
    console.log("renderProtectedRoutes", authAccess, auth);
    if (
      authAccess &&
      auth &&
      (allowedRoles.includes(auth.role) || allowedRoles.includes(APP_ROLES.ALL))
    ) {
      return children;
    } else if (auth) {
      if (auth.role === APP_ROLES.OBOEDIO_ADMIN) {
        return navigate(APP_PATHS.ORG_ADMIN_MANAGEMENT);
      } else if (auth.role === APP_ROLES.ORG_ADMIN) {
        if (auth.profile_status === PROFILE_STATUS.PENDING || auth.is_onboarded === 'NO') {
          return navigate(APP_PATHS.ORG_ONBOARDING);
        } else {
          // return navigate(APP_PATHS.ORG_DASHBOARD);
          return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
        }
      } else if (auth.role === APP_ROLES.ORG_USER) {
        return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
      } else if (auth.role === APP_ROLES.VENDOR_USER) {
        return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
      }
      else if (auth.role === APP_ROLES.AUDITOR) {
        return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
      }
      else if (auth.role === APP_ROLES.BRANCH_ADMIN) {
        return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
      } else if (auth.role === APP_ROLES.EXECUTIVE_USER) {
        return navigate(APP_PATHS.ORG_EXECUTIVE);
      } else if (auth.role === APP_ROLES.SUPPORT_USER) {
        return navigate(APP_PATHS.ORGADMIN_LIST);
      }
    } else if (authAccess && !auth) {
      return navigate(APP_PATHS.LOGIN);
    } else if (!authAccess) {
      return children;
    }
  }, [isLoggedIn, auth, allowedRoles, authAccess]);
  return <React.Fragment>{renderProtectedRoutes()}</React.Fragment>;
};
export default React.memo(ProtectedRoute);
