import React, { useEffect, useState } from "react";
import {
  useApiQuery,
  _getQueryState,
  _useMutation,
} from "../../../services/useAxiosQuery";
import { APP_ROLES, REQUEST_TYPE, RQ_KEYS } from "../../../utils/constants";
import { API_ROUTE } from "../../../utils/api";
import styles from "./style.module.scss";
import { Breadcrumb } from "../../../common/Parts";
import AddEditUsetDetailsModal from "../../../common/Parts/ModalPopups/UserDetailsUpdateModal/index";
import { useQueryClient } from "react-query";
import { ReactComponent as BackIcon } from "../../../assests/svg/backArrow.svg";
import { Button } from "../../../common/components";
import { useNavigate } from "react-router-dom";
import LoadingHoc from "../../../common/components/LoadingHoc";

function Index() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [HandleLoader, setHandleLoader] = useState(true);

  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: RQ_KEYS.DASHBOARD_LOCATIONS_DETAILS,
    });
  };
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const { mutateAsync } = _useMutation({ onSuccess });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (formData: any) => {
    console.log("Form data submitted:", formData);
    closeModal();
    const result = mutateAsync({
      url: API_ROUTE.UPDATE_USER_DATA,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
      },
    });

    console.log(result);
  };

  let UserDetails: any = useApiQuery({
    queryKey: RQ_KEYS.USER_LOGIN_DETAILS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_DATA}`,
  });

  console.log("UserDetails", UserDetails.data);

  useEffect(() => {
    if (UserDetails.data) {
      setHandleLoader(false);
    }
  }, [HandleLoader, UserDetails]);

  function UpdateUserData() {
    openModal();
  }

  return (
    <LoadingHoc isLoading={HandleLoader}>
      <div className={styles.MainContainer}>
        {isModalOpen === true ? (
          <AddEditUsetDetailsModal
          openState={isModalOpen}
            onClickCancel={closeModal}
            heading="Edit User Details"
            type="UPDATE"
            data={{
              first_name: UserDetails.data[0].first_name,
              last_name: UserDetails.data[0].last_name,
              email: UserDetails.data[0].email,
            }}
            onSubmit={handleSubmit}
          />
        ) : (
          <p></p>
        )}

        <div className={styles.breadCrumbManager}>
          <Button
            label=""
            id="backBtn"
            color="var(--blackColor)"
            background="transparent"
            fontSize="var(--secondaryFont)"
            onClick={() => {
              navigate(-1);
            }}
            border="none"
            Icon={
              <BackIcon
                className="svg_image_icon"
                fill="#0097ce"
                style={{ width: "30px" }}
              />
            }
          />

          <Breadcrumb
            pageName="Profile Page"
            showBtn="true"
            // org_name={
            //   UserDetails && UserDetails.data
            //     ? UserDetails?.data[0]?.org_name
            //     : ""
            // }
          />
        </div>

        {UserDetails && UserDetails.data ? (
          <div className={styles.DataContainer}>
            <p className={styles.pageHead}>Profile Details</p>

            <p className={styles.ParaContainer}>PERSONAL DETAILS</p>

            <div className={styles.DetailsContainer}>
              <p>
                <span>First Name:</span>
                {UserDetails?.data[0]?.first_name
                  ? UserDetails.data[0].first_name
                  : "N/A"}
              </p>
              <p>
                <span>Last Name:</span>
                {UserDetails?.data[0]?.last_name
                  ? UserDetails.data[0].last_name
                  : "N/A"}
              </p>
              <p>
                <span>Email:</span>
                {UserDetails?.data[0]?.email
                  ? UserDetails.data[0].email
                  : "N/A"}
              </p>
              <p>
                <span>Branch Name:</span>
                {UserDetails?.data[0]?.branch_name
                  ? UserDetails.data[0].branch_name
                  : "N/A"}
              </p>
              <p>
                <span>Profile Status:</span>
                {UserDetails?.data[0]?.profile_status
                  ? UserDetails?.data[0]?.profile_status.charAt(0).toUpperCase() + UserDetails?.data[0]?.profile_status.slice(1).toLowerCase()
                  : "N/A"}
              </p>
              <p>
                <span> Account Status:</span>
                {UserDetails?.data[0]?.is_active
                  ? UserDetails?.data[0]?.is_active.charAt(0).toUpperCase() + UserDetails?.data[0]?.is_active.slice(1).toLowerCase()
                  : "N/A"}
              </p>
              {(userAuthData?.role === APP_ROLES.VENDOR_USER) &&  <p>
                <span>Vendor Organization Name:</span>
                {UserDetails?.data[0]?.vendor_org_name
                  ? UserDetails.data[0].vendor_org_name
                  : "N/A"}
              </p> }

              {(userAuthData?.role === APP_ROLES.VENDOR_USER) &&  <p>
                <span> Product Name:</span>
                {UserDetails?.data[0]?.product_name
                  ? UserDetails.data[0].product_name
                  : "N/A"}
              </p> }
              
          {(userAuthData?.role === APP_ROLES.VENDOR_USER) &&
    <p>
    <span>Website:</span>
    {UserDetails?.data[0]?.product_website ? (
      <a
        href={
          UserDetails.data[0].product_website.startsWith("http://") ||
          UserDetails.data[0].product_website.startsWith("https://")
            ? UserDetails.data[0].product_website
            : `https://${UserDetails.data[0].product_website}`
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Website URL
      </a>
    ) : (
      "N/A"
    )}
  </p>
    }
                       </div>

            <p className={styles.ParaContainer}>OFFICE LOCATION</p>

            <div className={styles.DetailsContainer}>
              <p>
                <span>Street :</span>
                {UserDetails?.data[0]?.street_address_1
                  ? UserDetails.data[0].street_address_1
                  : "N/A"}
              </p>
              {/* <p>
                <span>Area:</span>
                {UserDetails?.data[0]?.street_address_2
                  ? UserDetails.data[0].street_address_2
                  : "N/A"}
              </p> */}
              <p>
                <span>City:</span>
                {UserDetails?.data[0]?.city ? UserDetails.data[0].city : "N/A"}
              </p>
              <p>
                <span>State:</span>
                {UserDetails?.data[0]?.state
                  ? UserDetails.data[0].state
                  : "N/A"}
              </p>
              <p>
                <span>Country:</span>
                {UserDetails?.data[0]?.country
                  ? UserDetails.data[0].country
                  : "N/A"}
              </p>
              <p>
                <span>Region:</span>
                {UserDetails?.data[0]?.region
                  ? UserDetails.data[0].region
                  : "N/A"}
              </p>
            </div>
          </div>
        ) : (
          <p>No Details Found</p>
        )}

        <div></div>
      </div>
    </LoadingHoc>
  );
}
export default Index;
