import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./routing";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { disableConsoleOutput } from "./utils/remove-console";


// const queryClient = new QueryClient({});.
const queryClient = new QueryClient({
  defaultOptions: {
    // queries: {
    //   cacheTime: 1000 * 60 * 60 * 48, // 24 hours
    //   staleTime:  1000,
    //   refetchOnWindowFocus: false,
    //   retry: 0,
    //   // refetchOnReconnect: false,
    //   // keepPreviousData: true,
    // },
    queries: {
         cacheTime: 1000 * 60 * 60 * 48, // 48 hours
         refetchOnWindowFocus: false,
         refetchOnReconnect: false,
         retry: 0,
         staleTime: 20 * 1000,
       },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const apiUrl = process.env.REACT_APP_API_URL;
if (process.env.NODE_ENV !== "development") {
  disableConsoleOutput();
}
console.log(`API URL: ${apiUrl}`);
console.log("process.env", process.env);
root.render(
  <React.StrictMode>
    <QueryClientProvider
     client={queryClient}
    >
      <Suspense fallback="Loading...............">
        <Routing />
      </Suspense>
      <ReactQueryDevtools 
        initialIsOpen={false} // Set to true to have it open by default
        position="bottom-right" // You can change to 'top-right', 'top-left', 'bottom-left'
        toggleButtonProps={{ style: { marginLeft: '1rem' } }}
      />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
