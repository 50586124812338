import React from "react";
import { Button, Table } from "../../../../common/components";
import { Breadcrumb } from "../../../../common/Parts";
import styles from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../../../assests/svg/home.svg";
import { ReactComponent as EditIcon } from "../../../../assests/svg/edit_icon.svg";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  ASSESSMENT_MODAL_NAMES,
  LOCALSTORAGE,
  ORGANIZATION_MODAL_NAMES,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import { IModal } from "../../../../interfaces/IModal";
import LocationModal from "../../../../common/Parts/ModalPopups/LocationModal";
import { ReactComponent as LocationIcon } from "../../../../assests/svg/map.svg";
import ManageOrganizationPopup from "../../../../common/Parts/ModalPopups/OrganizationModals/ManageOrganizationPopup";
import { useQueryClient } from "react-query";
import Tooltip from "../../../../common/Parts/ToolTip";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import { Spinner } from "react-bootstrap";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";

const OrgBranchLocations = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const locationMutate = _useMutation({
    onSuccess: () =>
      queryClient.refetchQueries({ queryKey: RQ_KEYS.USER_ORGANIZATION }),
  });
  const [showModal, setModal] = React.useState<IModal>({
    state: false,
    modalName: "",
  });

  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  const organizationLoadingState: any = _getQueryState({
    key: `${RQ_KEYS.USER_ORGANIZATION}_loading`,
  });
  useApiQuery({
    queryKey: RQ_KEYS.USER_ORGANIZATION,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_USER_ORGANIZATION}`,
  });

  const onSubmitAddLocation = (values: any) => {
    queryClient.setQueryData(RQ_KEYS.LOADING, true);

    locationMutate.mutate({
      url: API_ROUTE.CREATE_UPDATE_LOCATION,
      requestType: REQUEST_TYPE._PATCH,
      requestData: { ...values, org_id: organizationData?.org_id },
    });
  };

  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  //console.log(organizationLoadingState, "organizationLoadingState");
  return (
    <div
      className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
    >
      {/* <Breadcrumb
        pageName="Manage Organization"
        path={["Organization"]}
        showBtn="true"
        org_name={organizationData?.org_name}
      /> */}

      <div className={styles.headingAdminUser}>
        <Button
          label="Edit Orgnization Details"
          onClick={() => {
            setModal({
              state: true,
              modalName: ORGANIZATION_MODAL_NAMES.MANAGE_ORGANIZATION,
              type: "UPDATE",
            });
          }}
          id="editbtn"
          Icon={
            <EditIcon fill="var(--whiteColor)" className={`${styles.EditIcon} svg_image_icon`} />
          }
        />
        <div className={styles.headingAdminTop}>
          <h5>
            <strong>
              {/*   {organizationData?.length <= 1 ? "Industry" : "Industries"}: */}
              Industry:
            </strong>{" "}
            {organizationData?.org_industries?.join(", ")}
          </h5>
          <h5>
            <strong>Type:</strong> {organizationData?.org_type}
          </h5>
        </div>

        <div className={styles.manageAssessmentBodyBottom}>
          <div className={styles.adminAssessmentContentBottom}>
            <div className={styles.adminAssessmentLeft}>
              <h3>Office Locations</h3>
              <Button
                id="add_branch_location"
                label="Add Office Location"
                Icon={<LocationIcon fill="#ffffff" />}
                onClick={() => {
                  setModal((prev) => ({
                    ...prev,
                    state: true,
                    modalName: "CREATE LOCATION",
                    type: "CREATE",
                  }));
                }}
              ></Button>
            </div>

            <Breadcrumb pageName="Manage Organization" />
            <div className={styles.adminAssessmentList}>
              <LoadingHoc
                isLoading={organizationLoadingState}
                className={styles.assesmentLoader}
              >
                <Table
                  tableHeaders={[
                    "Office Location",
                    "Region",
                    "Street Address",
                    "City",
                    "State",
                    "ZIP Code",
                    "Country",
                  ]}
                  tableData={
                    organizationData?.branch_locations &&
                    organizationData.branch_locations?.length > 0
                      ? organizationData?.branch_locations?.map((item: any) => {
                          return {
                            branch_name:
                              item.branch_name && item.branch_name !== "null"
                                ? item.branch_name
                                : "N/A",
                            region:
                              item.region && item.region !== "null"
                                ? item.region
                                : "N/A",
                            street_address_1:
                              item.street_address_1 &&
                              item.street_address_1 !== "null"
                                ? //     ", "
                                  //   )
                                  item.street_address_1
                                : "N/A",
                            city:
                              item.city && item.city !== "null"
                                ? item.city
                                : "N/A",
                            state:
                              item.state && item.state !== "null"
                                ? item.state
                                : "N/A",
                            zipcode:
                              item.zipcode &&
                              item.zipcode !== "null" &&
                              item.zipcode !== "0"
                                ? item.zipcode
                                : "N/A",
                            country:
                              item.country && item.country !== "null"
                                ? item.country
                                : "N/A",
                            is_headquarter: item.is_headquarter,
                            olr_id: item?.olr_id,
                          };
                        })
                      : []
                  }
                  hideColumns={["org_type", "is_headquarter", "olr_id"]}
                  // showActionWithColumns={[
                  //   {
                  //     key: "Action",
                  //     button: (data: any) => {
                  //       console.log("BUTTON ONE DATA", data);
                  //     },
                  //   },
                  // ]}
                  action={{
                    name: "Action",
                    buttonOne: (data: any) => {
                      return (
                        // <Tooltip text="Edit">
                        //   <EditIcon
                        //     onClick={() => {
                        //       console.log(data);
                        //       setModal((prev) => ({
                        //         ...prev,
                        //         state: true,
                        //         modalName: "UPDATE LOCATION",
                        //         type: "UPDATE",
                        //         // data: organizationData,
                        //         data: {
                        //           ...data,
                        //           zipcode:
                        //             data.zipcode === "N/A" ? "" : data.zipcode,
                        //         },
                        //       }));
                        //     }}
                        //     style={{ maxWidth: "16px" }}
                        //   />
                        // </Tooltip>
                        <Button
                          onClick={() => {
                            console.log(data);
                            setModal((prev) => ({
                              ...prev,
                              state: true,
                              modalName: "UPDATE LOCATION",
                              type: "UPDATE",
                              // data: organizationData,
                              data: {
                                ...data,
                                zipcode:
                                  data.zipcode === "N/A" ? "" : data.zipcode,
                              },
                            }));
                          }}
                          label="Edit"
                          id="Edit"
                          Icon={
                            <EditIcon
                              fill="#ffffff"
                              className={`${styles.EditIcon} svg_image_icon`}
                            />
                          }
                          // fill="#000000"
                          // className="svg_image_icon"
                        />
                      );
                    },
                  }}
                />
              </LoadingHoc>
            </div>
          </div>
        </div>
      </div>
      {showModal?.state &&
        (showModal.modalName === "UPDATE LOCATION" ||
          showModal.modalName === "CREATE LOCATION") && (
          <LocationModal
            openState={showModal}
            heading={
              showModal?.type === "CREATE"
                ? "Add Office Location"
                : "Update Office Location"
            }
            // org_id={}
            type={showModal?.type}
            data={showModal?.data}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            onSubmit={onSubmitAddLocation}
            headQuarterExists={(() => {
              if (showModal?.type === "CREATE") {
                return organizationData?.branch_locations.some(
                  ({ is_headquarter }: any) =>
                    is_headquarter === true || is_headquarter === "Yes"
                );
              } else {
                return false;
              }
            })()}
          />
        )}

      {showModal.state &&
        showModal?.modalName === ORGANIZATION_MODAL_NAMES.MANAGE_ORGANIZATION &&
        showModal?.type === "UPDATE" && (
          <ManageOrganizationPopup
            heading="Update Organization Details"
            openState={showModal}
            data={organizationData}
            onClickCancel={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
            org_id={organizationData?.org_id}
            type={showModal?.type}
          />
        )}
      {/* {(() => console.log("showModal", showModal))()} */}
    </div>
  );
};
export default React.memo(OrgBranchLocations);
