import queryString from "query-string";
import {
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../../utils/constants";
import { API_ROUTE } from "../../../../../utils/api";
import { Button } from "../../../../../common/components";
import { ReactComponent as PlusIcon } from "../../../../../assests/svg/plus.icon.svg";
import { ReactComponent as ViewIcon } from "../../../../../assests/svg/view_icon.svg";
import { ReactComponent as BackArrow } from "../../../../../assests/svg/back_btn.svg";
import { ReactComponent as NextArrow } from "../../../../../assests/svg/next_btn.svg";
import { ReactComponent as SucessImage } from "../../../../../assests/svg/success_check.svg";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
import { useEffect, useState } from "react";
const parsedQuery = queryString.parse(window.location.search);

const QuestionSuccessScreen = ({
  // chapter_name,
  // assessment_id,
  // chapter_id,
  setEntryComplete,
  assessment,
}: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [parsedIds, setParsedIds] = useState({
    assessment_id: '',
    chapter_id: ''
  });


  console.log(">>>> parsedQuery",parsedQuery);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const assessmentId = parsed?.assessment_id ? atob(parsed.assessment_id as any) : '';
    const chapterId = parsed?.chapter_id ? atob(parsed.chapter_id as any) : '';
    
    setParsedIds({
      assessment_id: assessmentId,
      chapter_id: chapterId
    });
  }, []);

  let { data: chapterData, isLoading }: any = useApiQuery({
    queryKey: RQ_KEYS.ALL_CHAPTERS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSESSMENT_CHAPTERS}/${parsedIds.assessment_id}`,
    enabled: !!parsedIds.assessment_id
  });
  console.log("chapters in questionaire success", chapterData);

  let { data: nextChapterData, nextChapterLoading}: any = useApiQuery({
    queryKey: RQ_KEYS.NEXT_CHAPTERS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_NEXT_CHAPTER}/${parsedIds.assessment_id}/${parsedIds.chapter_id}`,
    enabled: !!parsedIds.assessment_id && !!parsedIds.chapter_id,
  });

  let { data: assignedChapterData, assignedChapterLoading}: any = useApiQuery({
    queryKey: RQ_KEYS.ASSIGNED_CHAPTERS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_ASSIGNED_CHAPTER_DATA}/${parsedIds.chapter_id}`,
    enabled: !!parsedIds.chapter_id,
  });

  console.log("chapters in questionaire success", nextChapterData);

  const { mutate: cloneArticle, isLoading: cloneArticleIsLoading } =
    _useMutation({
      queryKey: RQ_KEYS.CLONE_CHAPTER,
      onSuccess: (data: any) => {
        console.log("data after cloning assessment", data);
        // queryClient
        //   .refetchQueries({
        //     queryKey: RQ_KEYS.ALL_CHAPTERS,
        //   })
        //   .then();
        // queryClient
        //   .refetchQueries({
        //     queryKey: RQ_KEYS.QUESTIONS,
        //   })
        //   .then();
        // queryClient
        //   .refetchQueries({
        //     queryKey: RQ_KEYS.REMEDIATION_TASKS,
        //   })
        //   .then();
        // queryClient
        //   .refetchQueries({
        //     queryKey: RQ_KEYS.DOCUMENTS,
        //   })
        //   .then();
        // setEntryComplete(false);

        localStorage.removeItem("questionIndex");

        console.log(">>> Redirected on Next Chapter.",parsedIds.assessment_id, data?.data?.assign_chapter_id,  data?.data)

        queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS }).then();
        navigate(
          `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
            parsedIds.assessment_id!.toString()
          )}`
        );

        // navigate(
        //   `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${
        //     parsedIds.assessment_id && btoa(parsedIds.assessment_id)
        //   }&chapter_id=${
        //     data?.data?.assign_chapter_id && btoa(data?.data?.assign_chapter_id)
        //   }`,
        //   { replace: true }
        // );

        // navigate(0);
      },
    });

  const handleViewRopa = () => {
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS }).then();
    navigate(
      `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
        parsedIds.assessment_id!.toString()
      )}`
    );

    // navigate(0);
  };

  const handleCloneArticle = () => {
    cloneArticle({
      url: API_ROUTE.CLONE_CHAPTER,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        chapter: chapterData[0],
        chapters_length: chapterData.length,
      },
    });
  };
  const handleGoBack = () => {
    queryClient
          .refetchQueries({
            queryKey: RQ_KEYS.ALL_CHAPTERS,
          })
          .then();
    navigate(
      `${APP_PATHS.ASSESSMENT_CHAPTER_LIST}?assessment_id=${btoa(
        parsedIds.assessment_id!.toString()
      )}`
    );
  };
  const handleGoNext = () => {
    console.log(">>> Redirected on Next Chapter.",navigate)
    console.log(">>> assessment_id",parsedIds.assessment_id, ">> nextChapterData?.data?.assign_chapter_id",nextChapterData?.data?.assign_chapter_id)
    navigate(
      `${APP_PATHS.QUESTION_SCREEN}?assessment_id=${parsedIds.assessment_id && btoa(parsedIds.assessment_id)
      }&chapter_id=${nextChapterData?.assign_chapter_id &&
      btoa(nextChapterData?.assign_chapter_id)
      }`,{ replace: true });
  };

  return (
    <LoadingHoc isLoading={isLoading}>
      {assignedChapterData?.assessment_name === "Record of Processing Activities" ? (
        <div className={styles.successQuestionaireContainer}>
          <div className={styles.headingBlock}>
            <h3>You have successfully saved the <span className={styles.chapterBlock}> {assignedChapterData?.chapter_name} </span></h3>
            <div className={styles.topBtnContainer}>

              <div className={styles.ropaButtonContainer}>
                <Button
                  label="Add Another ROPA Entry"
                  id="add_ropa"
                  type="button"
                  Icon={
                    <PlusIcon
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  loading={cloneArticleIsLoading}
                  disabled={cloneArticleIsLoading}
                  onClick={handleCloneArticle}
                />
                <Button
                  label="View ROPA Entries"
                  id="view_ropa"
                  type="button"
                  Icon={
                    <ViewIcon
                      fill="#ffffff"
                      width={50}
                      height={50}
                      className="svg_image_icon"
                    />
                  }
                  padding="6px 20px"
                  onClick={handleViewRopa}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.successQuestionaireContainer}>
          <div className={styles.headingBlock}>
            <h3 className={styles.messageBlock} >You've Successfully Saved your response for</h3>
            <h4 className={styles.chapterBlock} >Chapter {assignedChapterData?.chapter_number}: {assignedChapterData?.chapter_name}</h4>
            <div className={styles.topBtnContainer}>
              <div className={styles.backButtonContainer}>
                <Button
                  label="Go Back to Chapter List"
                  id="go_back"
                  type="button"
                  // Icon={
                  //   <BackArrow
                  //     fill="#ffffff"
                  //     width={50}
                  //     height={50}
                  //     className="svg_image_icon"
                  //   />
                  // }
                  padding="6px 20px"
                  color="black"
                  background="none"
                  fontWeight="bold"
                  border="1px solid var(--primaryColor)"
                  onClick={handleGoBack}
                />
              </div>
              <div className={styles.ropaButtonContainer}>
                <Button
                  label="Next Chapter"
                  id="go_next"
                  type="button"
                  // Icon={
                  //   <NextArrow
                  //     fill="#ffffff"
                  //     width={50}
                  //     height={50}
                  //     className="svg_image_icon"
                  //   />
                  // }
                  padding="6px 20px"
                  onClick={handleGoNext}
                  disabled={nextChapterLoading || nextChapterData == '' || nextChapterData === undefined}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </LoadingHoc>
  );
};

export default QuestionSuccessScreen;
