import { useQueryClient } from "react-query";
import { _GET, _POST } from "../../services/axios.methods";
import { API_ROUTE } from "../../utils/api";
import {
  _getQueryState,
  _useMutation,
  _useMutationV2,
} from "../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  LOCALSTORAGE,
  PROFILE_STATUS,
  RQ_KEYS,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { useManageOrganization } from "../story/common/useOrganizationDetails";

const refetchUserAuthApi = async () => {
  const data = await _GET(`${API_ROUTE.MATCH_USER_AUTH}`);
  return data;
};

export const useAuthentication = () => {
  const queryClient = useQueryClient();
  const matchTokenLoadingState = _getQueryState({ key: `${RQ_KEYS.AUTH}_loading` });
  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const onSuccess = ({ data }: any) => {
    console.log("success_data", data)
    const token = data?.token;
    const userAuthData = decodeToken(token);
    localStorage.setItem(LOCALSTORAGE.TOKEN, token);
    queryClient.setQueryData(RQ_KEYS.USER_DATA, userAuthData);
  }


  // const onSuccess = async ({ data }: any) => {
  //   const token = data.token;
  //   const auth: any = decodeToken(token);
  //   await localStorage.setItem(LOCALSTORAGE.TOKEN, token);
  //   queryClient.setQueryData(RQ_KEYS.AUTH, token);
  //   queryClient.setQueryData(RQ_KEYS.USER_DATA, auth);
  //   await getOrganizationDetails();
  //   if (auth.role === APP_ROLES.OBOEDIO_ADMIN) {
  //     return navigate(APP_PATHS.ORG_ADMIN_MANAGEMENT);
  //   } else if (auth.role === APP_ROLES.EXECUTIVE_USER) {
  //     return navigate(APP_PATHS.ORG_DASHBOARD);
  //   } else if (auth.role === APP_ROLES.SUPPORT_USER) {
  //     return navigate(APP_PATHS.ORG_DASHBOARD);
  //   } else if (auth.role === APP_ROLES.ORG_ADMIN) {
  //     if (auth.profile_status === PROFILE_STATUS.PENDING && auth.is_onbaroded !== "YES" ) {
  //       return navigate(APP_PATHS.ORG_ONBOARDING);
  //     } else {
  //       return navigate(APP_PATHS.ORG_DASHBOARD);
  //     }
  //   } else if (auth.role === APP_ROLES.ORG_USER) {
  //     return navigate(APP_PATHS.ASSIGNED_TASKS);
  //   } else if (auth.role === APP_ROLES.VENDOR_USER) {
  //     return navigate(APP_PATHS.ASSESSMENT_DASHBOARD);
  //   } else if (auth.role === APP_ROLES.BRANCH_ADMIN) {
  //     return navigate(APP_PATHS.ASSIGNED_TASKS);
  //   } else if (auth.role === APP_ROLES.AUDITOR) {
  //     return navigate(APP_PATHS.ASSIGNED_TASKS);
  //   }
  // };
  const { mutate: refetchUserAuth } = _useMutationV2({
    apiCall: refetchUserAuthApi,
    onSuccess: onSuccess,
    showErrorToast: false,
    queryKey: RQ_KEYS.AUTH,
  });

  return { refetchUserAuth, matchTokenLoadingState, userAuthData };
};
