import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as CancelIcon } from "../../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as SendIcon } from "../../../../../assests/svg/send.svg";
import { Button, MultiSelect } from "../../../../../common/components";
import TaskList from "../../../../../common/components/TaskList";
import styles from "./style.module.scss";
// import TaskDetail from "../../../../common/Parts/SidePopups/TaskDetail";
import { useQueryClient } from "react-query";
import { Breadcrumb } from "../../../../../common/Parts";
import UserTaskDetail from "../../../../../common/Parts/SidePopups/UserTaskDetail";
import LoadingHoc from "../../../../../common/components/LoadingHoc";
import { Pagination } from "../../../../../common/components/Pagination";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../../utils/api";
import {
  APP_PATHS,
  ASSIGNED_STATUS,
  REQUEST_TYPE,
  RQ_KEYS,
  LIMIT,
  ASSESSMENT_STATUS
} from "../../../../../utils/constants";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../../utils/helpers";
import { useNavigate } from "react-router-dom";

const AssignedTasks = () => {
  const [activeTab, setActiveTab] = useState(0);
  // const [errorText, setErrorText] = useState("");
  const [taskDetailModal, setTaskDetailModal] = useState<any>({
    state: false,
    task: null,
  });
  const navigate = useNavigate();
  const [taskQuestion, setTaskQuestion] = useState<any>(null);

  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<any>(null);
  const { mutate: getAssignedTaskCountApi}= _useMutation({onSuccess:()=>{},queryKey: ['ASSIGNED_TASK_COUNT', selectedAssessmentId],})
  const { mutate: getTaskAssessmentsApi}= _useMutation({onSuccess:()=>{},queryKey: ['TASK_ASSESSMENT'],})

  const handleAssessmentFilter = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    if(selectedOption && selectedOption != null){
      setSelectedAssessmentId(selectedOption);
    }else{
      setSelectedAssessmentId(0);
    }
    
    console.log("selectedAssessment",selectedAssessmentId)
    };

    const mapTabIdToStatus = (tabId: string) => {
      switch (tabId) {
        case 'IN_PROGRESS':
          return 'Assigned';
        case 'COMPLETED':
          return 'Completed';
        case 'PENDING_REVIEW':
          return 'Under_Review';
        default:
          return 'Assigned';
      }
    };

    useMemo(()=>{
      console.log("Assesment Id in useeffect",selectedAssessmentId)
      if(selectedAssessmentId){
        getAssignedTaskCountApi({
          url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN_COUNT}?assign_assessment_id=${selectedAssessmentId}`,
          requestType: REQUEST_TYPE._GET,
        })
      }
    },[selectedAssessmentId])
    useEffect(()=>{
      console.log("Assesment Id in useeffect",selectedAssessmentId)
      console.log("Active Tab in use Effect:", tabs[activeTab].id, "type:", typeof(tabs[activeTab].id));
      const taskStatus:string = mapTabIdToStatus(tabs[activeTab].id);

      getTaskAssessmentsApi({
        url: `${API_ROUTE.TASKS_ASSESSMENT}?task_type=assignTask`,
        requestType: REQUEST_TYPE._GET,
      })

      if(selectedAssessmentId || selectedAssessmentId == 0){
        switch(taskStatus){
          case 'Assigned':
            getAssignedTasks({
              url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Assigned&filterAssessment=${selectedAssessmentId}`,
              requestType: REQUEST_TYPE._GET,
            });
            break;
          case 'Completed':
            getCompletedTasks({
              url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Completed&filterAssessment=${selectedAssessmentId}`,
              requestType: REQUEST_TYPE._GET,
            });
            break;
          case 'Under_Review':
            getReviewTasks({
              url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Under_Review&filterAssessment=${selectedAssessmentId}`,
              requestType: REQUEST_TYPE._GET,
            });
            break;
        }
      }

      getAssignedTaskCountApi({
        url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN_COUNT}?assign_assessment_id=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      })
    },[activeTab, selectedAssessmentId])

  const queryClient = useQueryClient();
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  useApiQuery({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
    url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Assigned&filterAssessment=${selectedAssessmentId}`,
    requestType: REQUEST_TYPE._GET,
  });
  const pendingTasks:any = _getQueryState({
    key: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
  });
  const pendingTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK}_loading`,
  });
  const reviewTasks:any = _getQueryState({
    key: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
  });
  const reviewTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS}_loading`,
  });
  const completedTasks:any = _getQueryState({
    key: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
  });
  const completedTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS}_loading`,
  });

  const updateTaskAssignedStatusLoading = _getQueryState({
    key: `${RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS}_loading`,
  });

  const { mutate: getReviewTasks } = _useMutation({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
  });

  const { mutate: getCompletedTasks } = _useMutation({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
  });

  const { mutate: getAssignedTasks } = _useMutation({
    queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
  });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });
  const { mutate: updateAssignedStatus } = _useMutation({
    onSuccess: () => {
      console.log("onSuccess in updateAssingedStatus");
      getReviewTasks({
        url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=10&offset=0&assigned_status=Under_Review`,
        requestType: REQUEST_TYPE._GET,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TO_REVIEW_TASKS,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
      });

      queryClient.refetchQueries({
        queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_TASK_COUNT,
      });
      setTaskDetailModal({ state: false, task: null });
    },
    queryKey: RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS,
  });

  const taskCount:any = _getQueryState({ key:  ['ASSIGNED_TASK_COUNT', selectedAssessmentId] });
  // const countLoading = _getQueryState({
  //   key: `${RQ_KEYS.PENDING_TASKS}_loading`,   
  // });
  console.log("NEW taskCount",taskCount)

  const taskAssessments:any = _getQueryState({ key:  ['TASK_ASSESSMENT'] });
  console.log("taskAssessments in Assign Tasks", taskAssessments)

  const tabs = [
    {
      title: `In Progress (${taskCount?.pendingTaskCount > 0 ? taskCount?.pendingTaskCount : 0
        })`,
      id: "IN_PROGRESS",
    },
    {
      title: `Pending Review (${taskCount?.reviewTaskCount > 0 ? taskCount?.reviewTaskCount : 0
        })`,
      id: "PENDING_REVIEW",
    },
    {
      title: `Completed (${taskCount?.completedTaskCount > 0 ? taskCount?.completedTaskCount : 0
        })`,
      id: "COMPLETED",
    },
  ];

  const handleTabClick = (index: any) => {
    setActiveTab(index);
    if (index === 0) {
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_PENDING_TASK,
      });
    } else if (index === 1) {
      getReviewTasks({
        url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Under_Review&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (index === 2) {
      getCompletedTasks({
        url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Completed&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });

      queryClient.refetchQueries({
        queryKey: RQ_KEYS.ASSIGNED_BY_ADMIN_COMPLETED_TASKS,
      });
    }
    queryClient.resetQueries({ queryKey: RQ_KEYS.COMMENTS });
  };
  console.log(tabs[activeTab].id, " tab view");

  const { data: questionData, refetch } = useApiQuery({
    queryKey: [RQ_KEYS.TASK_QUESTION, taskDetailModal?.task?.question_id],
    url: `${API_ROUTE.TASK_QUESTION}?question_id=${taskDetailModal?.task?.question_id}`,
    requestType: REQUEST_TYPE._GET,
    enabled: !!taskDetailModal?.task?.question_id,
    onSuccess: (data: any) => {
      console.log("Task Question Data:", data);
      setTaskQuestion(data);
    },
  });

  useEffect(() => {
    if (taskDetailModal?.task?.question_id) {
      refetch();
    }
  }, [taskDetailModal?.task?.question_id, refetch]);

   const onPaginzationChange = ({ offset }: any) => {
      setDataLimit((prev: any) => ({
        ...prev,
        offset,
      }));
  
      if (tabs[activeTab].id === "COMPLETED") {
        getCompletedTasks({
          url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=Completed&filterAssessment=${selectedAssessmentId}`,
          requestType: REQUEST_TYPE._GET,
        });
      } else if (tabs[activeTab].id === "IN_PROGRESS") {
        getAssignedTasks({
          url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=Assigned&filterAssessment=${selectedAssessmentId}`,
          requestType: REQUEST_TYPE._GET,
        });
      } else if (tabs[activeTab].id === "PENDING_REVIEW") {
        getReviewTasks({
          url: `${API_ROUTE.TASK_ASSIGNED_BY_ADMIN}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=Under_Review&filterAssessment=${selectedAssessmentId}`,
          requestType: REQUEST_TYPE._GET,
        });
      }
    };
  


  const renderTasks: any = useCallback(() => {
    switch (tabs[activeTab].id) {
      case "IN_PROGRESS":
        return (
          <LoadingHoc isLoading={pendingTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="In Progress"
              tasks={pendingTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                // return;
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      case "PENDING_REVIEW":
        return (
          <LoadingHoc isLoading={reviewTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="To Review"
              tasks={reviewTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      case "COMPLETED":
        return (
          <LoadingHoc isLoading={completedTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="Completed"
              tasks={completedTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      default:
        <p>No Task Found</p>;
    }
  }, [activeTab, pendingTasks, reviewTasks, completedTasks,selectedAssessmentId]);

  const { mutate: submitAnswer, isLoading: submitAnswerLoading } = _useMutation(
    {
      onSuccess: (data: any) => {
        console.log("data in submit answer", data);
      }
    }
  );

  const handleMarkAsAssigned = ({ org_assign_assessment_id, comment_thread_id, notes }: any) => {
    // console.log("org_assign_assessment_id", assignee_notes);
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.ASSIGNED,
        comment_thread_id: comment_thread_id,
        assignee_notes: notes
      },
    });
  };

  const handleMarkAsComplete = ({ org_assign_assessment_id,comment_thread_id }: any) => {
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.COMPLETED,
        comment_thread_id
      },
    });
  };

  const onSubmitAnswer = (answer: string, question_oaa_id: any, isComplete: boolean) => {
    console.log("question_oaa_id:", question_oaa_id);
    submitAnswer({
      url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        answer: answer,
        org_assign_assessment_id: question_oaa_id,
        isComplete,
        // followupAnswers: currentFollowupAnswers,
      },
    });
  };




  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }

  const currentSelectedAssessment = taskAssessments?.find(
    (option: any) => option.assign_assessment_id === selectedAssessmentId
  ); 

  return (
    <div className={styles.userTasksContainer}>
      {/* <Breadcrumb pageName="Tasks" org_name={organizationData?.org_name} /> */}
      <div className={styles.assessmentFilter}>
          <MultiSelect
            // label="Select Assessment"
            name="assessments"
            placeholder="Select Assessment"
            menuPlacement='top'
            isDisabled={!taskAssessments?.length}
            options={taskAssessments?.map(
              ({
                assessment_name,
                assign_assessment_id
              }: any) => ({
                label: assessment_name,
                value: assign_assessment_id
              })
            ) || []}

            value={selectedAssessmentId ? taskAssessments?.find(
              (option: any) => option.assign_assessment_id === selectedAssessmentId
            ) : 0}

            onChange={(selectedOption:any) => {
              console.log("selectedOption",selectedOption);
              handleAssessmentFilter(selectedOption);
            }}
          ></MultiSelect>
        </div>
        <div className={styles.assessmentInfoSection}> <span className={styles.assessmentInfo}> Showing tasks for: </span> { currentSelectedAssessment?.assessment_name ? currentSelectedAssessment?.assessment_name : 'All Assessments' }</div>

      <div className={styles.tabsHeader}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={
              activeTab === index
                ? `${styles.active} ${styles.tabIn}`
                : `${styles.tabIn}`
            }
          >
            <Button
              label={tab.title}
              id="tabTitle"
              fontWeight="bold"
              fontSize="calc(var(--secondaryFont) + 2px)"
              border="none"
              padding="6px 0px"
              background="transparent"
              color="var(--blackColor)"
              onClick={() => handleTabClick(index)}
              borderRadius="0"
            />
          </div>
        ))}
      </div>
      <div className={styles.tasksBody}>{renderTasks()}</div>
      {taskDetailModal && taskDetailModal?.state && (
        <UserTaskDetail
          openState={taskDetailModal}
          heading="Task Details"
          onClickCancel={() => {
            setTaskDetailModal({ state: false, task: null });
          }}
          task={taskDetailModal && taskDetailModal.task}
          isReadOnly={
            taskDetailModal &&
            taskDetailModal?.task &&
            (taskDetailModal?.task?.assigned_status ===
              ASSIGNED_STATUS.ASSIGNED ||
              taskDetailModal?.task?.assigned_status ===
              ASSIGNED_STATUS.COMPLETED ||
              taskDetailModal.task?.assessment_status ===
              ASSESSMENT_STATUS.COMPLETED)
          }
          buttonPrimary={({
            org_assign_assessment_id,
            notes,
            handleAddComment,
            question_oaa_id,
            isDisable,
            comment_thread_id
          }: any) => {
            const yesOption = taskQuestion?.options?.find(
              (option: any) => option.option_text === "Yes"
            );
            return (
              <Button
                type="button"
                label="Mark as Complete"
                id="primary"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  handleMarkAsComplete({ org_assign_assessment_id,comment_thread_id });
                  // onSubmitAnswer(`${yesOption?.assign_option_id}`, question_oaa_id, true);
                  handleAddComment();
                }}
                loading={updateTaskAssignedStatusLoading}
                disabled={updateTaskAssignedStatusLoading}
                Icon={
                  <SendIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: "14px" }}
                  />
                }
              />
            );
          }}
          buttonSecondary={({
            org_assign_assessment_id,
            handleAddComment,
            isDisable,
            comment_thread_id,
            notes
          }: any) => {
            console.log({ isDisable });


            const isButtonVisible = taskDetailModal?.task?.assigned_status !== ASSIGNED_STATUS.ASSIGNED &&
              taskDetailModal?.task?.assigned_status !== ASSIGNED_STATUS.IN_PROGRESS;

            return isButtonVisible ? (
              <Button
                label="Send Back for Rework"
                id="secondary"
                disabled={isDisable}
                // background="white"
                // border="1px solid var(--secondaryColor)"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  handleMarkAsAssigned({ org_assign_assessment_id, comment_thread_id, notes });
                  handleAddComment();
                }}
                Icon={
                  <CancelIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: "14px" }}
                  />
                }
              />
            ) : null;
          }}
          isCommentRequired={true}
        />
      )}

    <Pagination
            key={activeTab}
            data={
              tabs[activeTab].id === "IN_PROGRESS"
                ? pendingTasks
                : tabs[activeTab].id === "PENDING_REVIEW"
                ? reviewTasks
                : completedTasks
            }
            totalCount={
              tabs[activeTab].id === "IN_PROGRESS"
                ? taskCount?.pendingTaskCount
                : tabs[activeTab].id === "PENDING_REVIEW"
                ? taskCount?.reviewTaskCount
                : taskCount?.completedTaskCount
            }
            onPaginzationChange={onPaginzationChange}
            setDataLimit={setDataLimit}
            dataLimit={dataLimit}
          />
    </div>
  );
};

export default React.memo(AssignedTasks);
