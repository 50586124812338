import React, { useCallback, useState, useMemo, useEffect } from "react";   
import styles from "./style.module.scss";
import { Button, MultiSelect } from "../../../../common/components";
import { ReactComponent as CancelIcon } from "../../../../assests/svg/cancel_icon.svg";
import { ReactComponent as SendIcon } from "../../../../assests/svg/send.svg";
import TaskList from "../../../../common/components/TaskList";
// import TaskDetail from "../../../../common/Parts/SidePopups/TaskDetail";
import { Breadcrumb } from "../../../../common/Parts";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import {
  APP_PATHS,
  APP_ROLES,
  ASSESSMENT_STATUS,
  ASSIGNED_STATUS,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import LoadingHoc from "../../../../common/components/LoadingHoc";
import { useQueryClient } from "react-query";
import UserTaskDetail from "../../../../common/Parts/SidePopups/UserTaskDetail";
import { Pagination } from "../../../../common/components/Pagination";
import {
  capitalizeFirstLetter,
  getAdminRoleFromKey,
} from "../../../../utils/helpers";
import { useNavigate } from "react-router-dom";

const AssignedTasks = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [taskDetailModal, setTaskDetailModal] = useState<any>({
    state: false,
    task: null,
  });
  let includeMyTask:boolean = true;
  const [taskQuestion, setTaskQuestion] = useState<any>(null);

  const [dataLimit, setDataLimit] = useState<any>({ limit: LIMIT, offset: 0 });

  const [selectedAssessmentId, setSelectedAssessmentId] = useState<any>(null);
  const { mutate: getTaskCountApi}= _useMutation({onSuccess:()=>{},queryKey: ['TASK_COUNT', selectedAssessmentId],})
  const { mutate: getTaskAssessmentsApi}= _useMutation({onSuccess:()=>{},queryKey: ['TASK_ASSESSMENT'],})

  console.log("activeTab:",activeTab);

  const handleAssessmentFilter = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    if(selectedOption && selectedOption != null){
      setSelectedAssessmentId(selectedOption);
    }
    else{
      setSelectedAssessmentId(0);
    }
    console.log("selectedAssessment",selectedAssessmentId)
  };
  
useMemo(()=>{
  console.log("Assesment Id in useeffect",selectedAssessmentId)
  if(selectedAssessmentId){
      getTaskCountApi({
      url: `${API_ROUTE.ASSIGNED_TASKS_COUNT}?assign_assessment_id=${selectedAssessmentId}`,
      requestType: REQUEST_TYPE._GET,
    })
  }
},[selectedAssessmentId])
useEffect(()=>{
  console.log("Assesment Id in useeffect",selectedAssessmentId)
  console.log("Active Tab in use Effect:", tabs[activeTab].id);

  const mapTabIdToStatus = (tabId: string) => {
    switch (tabId) {
      case 'NOT_STARTED':
        return 'Assigned';
      case 'IN_PROGRESS':
        return 'In_Progress';
      case 'PENDING_REVIEW':
        return 'Under_Review';
      case 'COMPLETED':
        return 'Completed';  
      default:
        return 'Assigned';
    }
  };

  const taskStatus:string = mapTabIdToStatus(tabs[activeTab].id);

  // getTaskAssessmentsApi({
  //   url: `${API_ROUTE.TASKS_ASSESSMENT}?task_type=myTask&task_status=${taskStatus}`,
  //   requestType: REQUEST_TYPE._GET,
  // })

     getTaskAssessmentsApi({
          url: `${API_ROUTE.TASKS_ASSESSMENT}?task_type=myTask`,
          requestType: REQUEST_TYPE._GET,
        })

  if(selectedAssessmentId || selectedAssessmentId == 0){
    console.log(">>> selectedAssessmentId", selectedAssessmentId);
          switch(taskStatus){
            case 'In_Progress':
              getInProgressTasks({
                url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=In_Progress&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
                requestType: REQUEST_TYPE._GET,
              });
              break;
            case 'Completed':
              getCompletedTasks({
                url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Completed&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
                requestType: REQUEST_TYPE._GET,
              });
              break;
            case 'Under_Review':
              getReviewTasks({
                url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Under_Review&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
                requestType: REQUEST_TYPE._GET,
              });
              break;
            case 'Assigned':
              getPendingTasks({
                url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Assigned&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
                requestType: REQUEST_TYPE._GET,
              });
              break;  
          }
        }

  getTaskCountApi({
    url: `${API_ROUTE.ASSIGNED_TASKS_COUNT}?assign_assessment_id=${selectedAssessmentId}`,
    requestType: REQUEST_TYPE._GET,
  })
},[activeTab, selectedAssessmentId])

  const queryClient = useQueryClient();
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });

  const { mutate: submitAnswer, isLoading: submitAnswerLoading } = _useMutation(
    {
      onSuccess: (data: any) => {
        console.log("data in submit answer", data);
      }
    }
  );
  const { data: questionData, refetch } = useApiQuery({
    queryKey: [RQ_KEYS.TASK_QUESTION, taskDetailModal?.task?.question_id],
    url: `${API_ROUTE.TASK_QUESTION}?question_id=${taskDetailModal?.task?.question_id}`,
    requestType: REQUEST_TYPE._GET,
    enabled: !!taskDetailModal?.task?.question_id,
    onSuccess: (data: any) => {
      console.log("Task Question Data:", data);
      setTaskQuestion(data);
    },
  });
  useApiQuery({
    queryKey: RQ_KEYS.PENDING_TASKS,
    url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Assigned&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
    requestType: REQUEST_TYPE._GET,
  });
  // useMemo(() => {
  //   if (taskDetailModal?.task?.question_id) {
  //     refetch();
  //   }
  // }, [taskDetailModal?.task?.question_id, refetch]);

  const taskCount:any = _getQueryState({ key:  ['TASK_COUNT', selectedAssessmentId] });
  // const countLoading = _getQueryState({
  //   key: `${RQ_KEYS.PENDING_TASKS}_loading`,
    
  // });
  console.log("NEW taskCount",taskCount)

    const taskAssessments:any = _getQueryState({ key:  ['TASK_ASSESSMENT'] });
    console.log("taskAssessments in My Tasks", taskAssessments)
  
  const pendingTasks:any = _getQueryState({ key: RQ_KEYS.PENDING_TASKS });
  const pendingTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.PENDING_TASKS}_loading`,
  });

  const inProgressTasks: any = _getQueryState({
    key: RQ_KEYS.IN_PROGRESS_TASKS,
  });

  const inProgressTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.IN_PROGRESS_TASKS}_loading`,
  });

  const reviewTasks:any = _getQueryState({ key: RQ_KEYS.TO_REVIEW_TASKS });
  const reviewTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.TO_REVIEW_TASKS}_loading`,
  });
  const completedTasks:any = _getQueryState({ key: RQ_KEYS.COMPLETED_TASKS });
  const completedTasksLoadingState = _getQueryState({
    key: `${RQ_KEYS.COMPLETED_TASKS}_loading`,
  });
  const updateTaskAssignedStatusLoading = _getQueryState({
    key: `${RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS}_loading`,
  });

  const { mutate: getPendingTasks } = _useMutation({
    queryKey: RQ_KEYS.PENDING_TASKS,
  });

  const { mutate: getInProgressTasks } = _useMutation({
    queryKey: RQ_KEYS.IN_PROGRESS_TASKS,
  });

  const { mutate: getReviewTasks } = _useMutation({
    queryKey: RQ_KEYS.TO_REVIEW_TASKS,
  });
  const { mutate: getCompletedTasks } = _useMutation({
    queryKey: RQ_KEYS.COMPLETED_TASKS,
  });

  const userAuthData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  const { mutate: updateAssignedStatus } = _useMutation({
    onSuccess: () => {
      handleTabClick(1);
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.PENDING_TASKS,
      });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.IN_PROGRESS_TASKS,
      });
      // queryClient.refetchQueries({
      //   queryKey: RQ_KEYS.TO_REVIEW_TASKS,
      // });
      // queryClient.refetchQueries({
      //   queryKey: RQ_KEYS.COMPLETED_TASKS,
      // });
      queryClient.refetchQueries({
        queryKey: RQ_KEYS.TASKS_COUNT,
      });
      // setTaskDetailModal({ state: false, task: null });
    },
    queryKey: RQ_KEYS.UPDATE_TASK_ASSIGNED_STATUS,
  });
  console.log("taskCount>> completedTasks", completedTasks);
  
  console.log("taskCount>> taskDetailModal", taskDetailModal);
  
  const tabs = useMemo(
    () => [
      {
        title: `Not Started (${
          taskCount?.pendingTaskCount > 0 ? taskCount?.pendingTaskCount : 0
        })`,
        id: "NOT_STARTED",
      },
      {
        title: `In Progress (${
          taskCount?.inProgressTaskCount > 0
            ? taskCount?.inProgressTaskCount
            : 0
        })`,
        id: "IN_PROGRESS",
      },
      {
        title: `Pending Review (${
          taskCount?.reviewTaskCount > 0 ? taskCount?.reviewTaskCount : 0
        })`,
        id: "PENDING_REVIEW",
      },
      {
        title: `Completed (${
          taskCount?.completedTaskCount > 0 ? taskCount?.completedTaskCount : 0
        })`,
        id: "COMPLETED",
      },
    ],
    [taskCount]
  );

  const handleTabClick = (index: any) => {
    setActiveTab(index);
    if (index === 0) {
      queryClient.refetchQueries({ queryKey: RQ_KEYS.PENDING_TASKS });
    } else if (index === 1) {
      getInProgressTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=In_Progress&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (index === 2) {
      getReviewTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Under_Review&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (index === 3) {
      getCompletedTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${dataLimit.offset}&assigned_status=Completed&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });

      queryClient.refetchQueries({ queryKey: RQ_KEYS.COMPLETED_TASKS });
    }
  };

  const onPaginzationChange = ({ offset }: any) => {
    setDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));

    if (tabs[activeTab].id === "NOT_STARTED") {
      getPendingTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=Assigned&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (tabs[activeTab].id === "IN_PROGRESS") {
      getInProgressTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=In_Progress&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (tabs[activeTab].id === "PENDING_REVIEW") {
      getReviewTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=Under_Review&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else {
      getCompletedTasks({
        url: `${API_ROUTE.ASSIGNED_TASKS}?limit=${dataLimit.limit}&offset=${offset}&assigned_status=Completed&includeMyTask=${includeMyTask}&filterAssessment=${selectedAssessmentId}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  };

  console.log(tabs[activeTab].id, " tab view");
  const renderTasks: any = useCallback(() => {
    switch (tabs[activeTab].id) {
      case "NOT_STARTED":
        return (
          <LoadingHoc isLoading={pendingTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="Not Started"
              tasks={pendingTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      case "IN_PROGRESS":
        return (
          <LoadingHoc isLoading={inProgressTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="In Progress"
              tasks={inProgressTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      case "PENDING_REVIEW":
        return (
          <LoadingHoc isLoading={reviewTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="To Review"
              tasks={reviewTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      case "COMPLETED":
        return (
          <LoadingHoc isLoading={completedTasksLoadingState}>
            <TaskList
              tab={tabs[activeTab].id}
              heading="Completed"
              tasks={completedTasks?.flatMap((assessment:any) => assessment.tasks_data)}
              onSelectTask={(state: boolean, task: any) => {
                console.log("onSelectTask", task);
                setTaskDetailModal({ state, task });
              }}
            />
          </LoadingHoc>
        );
      default:
        <p>No Task Found</p>;
    }
  }, [
    activeTab,
    pendingTasks,
    reviewTasks,
    completedTasks,
    completedTasksLoadingState,
    pendingTasksLoadingState,
    reviewTasksLoadingState,
    inProgressTasks,
    inProgressTasksLoadingState,
    tabs,
    selectedAssessmentId
  ]);

  const handleSendToReview = ({ org_assign_assessment_id, notes,comment_thread_id }: any) => {
    // console.log("org_assign_assessment_id", assignee_notes);
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.UNDER_REVIEW,
        assignee_notes: notes,
        comment_thread_id
      },
    });
  };

  const handleUpdateInProgress = ({ org_assign_assessment_id,comment_thread_id }: any) => {
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.IN_PROGRESS,
        comment_thread_id
      },
    });
  };



  function ShowUserProfile() {
    // console.log(userAuthData)
    if (
      userAuthData.role !== "OBOEDIO_ADMIN" &&
      userAuthData.role !== "SUPPORT_USER"
    ) {
      navigate(APP_PATHS.USER_LOGIN_DETAILS);
    }
  }
  const handleMarkAsComplete = ({ org_assign_assessment_id,comment_thread_id }: any) => {
    updateAssignedStatus({
      url: API_ROUTE.UPDATE_TASK_ASSIGNED_STATUS,
      requestType: REQUEST_TYPE._POST,
      requestData: {
        org_assign_assessment_id,
        assigned_status: ASSIGNED_STATUS.COMPLETED,
        comment_thread_id
      },
    });
  };
  const onSubmitAnswer = (answer: string, question_oaa_id: any, isComplete: boolean) => {
    console.log("question_oaa_id:", question_oaa_id);
    submitAnswer({
      url: API_ROUTE.SUBMIT_ASSESSMENT_ANSWER,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        answer: answer,
        org_assign_assessment_id: question_oaa_id,
        isComplete,
        // followupAnswers: currentFollowupAnswers,
      },
    });
  };

  const currentSelectedAssessment = taskAssessments?.find(
    (option: any) => option.assign_assessment_id === selectedAssessmentId
  ); 

  return (
    
    <div className={styles.userTasksContainer}>
      {/* <Breadcrumb pageName="Tasks" org_name={organizationData?.org_name} /> */}
      <div className={styles.assessmentFilter}>
          <MultiSelect
            // label="Select Assessment"
            name="assessments"
            placeholder="Select Assessment"
            menuPlacement='top'
            isDisabled={!taskAssessments?.length}
            options={taskAssessments?.map(
              ({
                assessment_name,
                assign_assessment_id
              }: any) => ({
                label: assessment_name,
                value: assign_assessment_id
              })
            ) || []}

            value={selectedAssessmentId ? taskAssessments?.find(
              (option: any) => option.assign_assessment_id === selectedAssessmentId
            ) : 0}

            onChange={(selectedOption:any) => {
              console.log("selectedOption",selectedOption);
              handleAssessmentFilter(selectedOption);
            }}
          ></MultiSelect>

          {/* <div className={styles.assessmentInfoSection}> <span className={styles.assessmentInfo}> Showing tasks for: </span> { currentSelectedAssessment?.assessment_name ? currentSelectedAssessment?.assessment_name : 'All Assessments' }</div> */}
        </div>

        <div className={styles.assessmentInfoSection}> <span className={styles.assessmentInfo}> Showing tasks for: </span> { currentSelectedAssessment?.assessment_name ? currentSelectedAssessment?.assessment_name : 'All Assessments' }</div>

        <div className={styles.tabsHeader}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={
                activeTab === index
                  ? `${styles.active} ${styles.tabIn}`
                  : `${styles.tabIn}`
              }
            >
              <Button
                label={tab.title}
                id="tabTitle"
                fontWeight="bold"
                fontSize="calc(var(--secondaryFont) + 2px)"
                border="none"
                background="transparent"
                color="var(--blackColor)"
                padding="6px 0"
                onClick={() => handleTabClick(index)}
                borderRadius="0"
              />
            </div>
          ))}        
      </div>
      <div className={styles.tasksBody}>{renderTasks()}</div>
      {taskDetailModal && taskDetailModal?.state && (
        <UserTaskDetail
          openState={taskDetailModal}
          heading="Task Details"
          onClickCancel={() => {
            setTaskDetailModal({ state: false, task: null });
          }}
          task={taskDetailModal && taskDetailModal.task}
          isReadOnly={
            taskDetailModal &&
            (taskDetailModal.task?.assigned_status ===
              ASSIGNED_STATUS.COMPLETED ||
              taskDetailModal.task?.assigned_status ===
                ASSIGNED_STATUS.UNDER_REVIEW ||
              taskDetailModal.task?.assigned_status ===
                ASSIGNED_STATUS.ASSIGNED ||
              taskDetailModal.task?.assessment_status ===
                ASSESSMENT_STATUS.COMPLETED)  
          }
          buttonPrimary={({
            org_assign_assessment_id,
            notes,
            handleAddComment,
            assigned_to_user_id,
            assigned_by_user_id,
            question_oaa_id,
            comment_thread_id,
            documents,
            comments,
            isDisable
          }: any) => {
            
            if(assigned_by_user_id && assigned_to_user_id && assigned_by_user_id === assigned_to_user_id){
              return (
                <Button
                  type="button"
                  label="Mark as Complete"
                  id="primary"
                  fontSize="calc(var(--secondaryFont) + 2px)"
                  onClick={() => {
                    // const yesOption = taskQuestion?.options?.find(
                    //   (option: any) => option.option_text === "Yes"
                    // );
                    // handleSendToReview({ org_assign_assessment_id, notes });
                    // handleAddComment();
                    // setTaskDetailModal({ state: false, task: null });

                    handleMarkAsComplete({ org_assign_assessment_id,comment_thread_id });
                  // onSubmitAnswer(`${yesOption?.assign_option_id}`, question_oaa_id, true);
                  handleAddComment();
                  setTaskDetailModal({ state: false, task: null });
                  }}
                  loading={updateTaskAssignedStatusLoading}
                  disabled={updateTaskAssignedStatusLoading || !documents?.length || isDisable}
                  Icon={
                    <SendIcon
                      fill="var(--whiteColor)"
                      style={{ maxWidth: "14px" }}
                    />
                  }
                />
              );
            } else{

              return (
                <Button
                  type="button"
                  label="Send For Review"
                  id="primary"
                  fontSize="calc(var(--secondaryFont) + 2px)"
                  onClick={() => {
                    handleSendToReview({ org_assign_assessment_id, notes,comment_thread_id });
                    handleAddComment();
                    setTaskDetailModal({ state: false, task: null });
                  }}
                  loading={updateTaskAssignedStatusLoading}
                  disabled={updateTaskAssignedStatusLoading || isDisable || !documents?.length}
                  Icon={
                    <SendIcon
                      fill="var(--whiteColor)"
                      style={{ maxWidth: "14px" }}
                    />
                  }
                />
              );
            }
          }}
          buttonSecondary={() => {
            return (
              <Button
                label="Cancel"
                id="secondary"
                gap="0px"
                background="transparent"
                color="var(--secondaryColor)"
                border="1px solid var(--secondaryColor)"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  setTaskDetailModal({ state: false, task: null });
                }}
                Icon={
                  <CancelIcon
                    fill="var(--whiteColor)"
                    // style={{ maxWidth: "18px" }}
                  />
                }
              />
            );
          }}
          buttonStartTask={({ org_assign_assessment_id,comment_thread_id }: any) => {
            return (
              <Button
                label="Start Task"
                id="startTask"
                gap="0px"
                // color="var(--secondaryColor)"
                border="1px solid var(--secondaryColor)"
                fontSize="calc(var(--secondaryFont) + 2px)"
                onClick={() => {
                  handleUpdateInProgress({ org_assign_assessment_id ,comment_thread_id});
                  setTaskDetailModal({
                    ...taskDetailModal,
                    task: {
                      ...taskDetailModal.task,
                      assigned_status: "In_Progress",
                    },
                  });
                }}
                Icon={
                  <SendIcon
                    fill="var(--whiteColor)"
                    style={{ maxWidth: "14px" }}
                  />
                }
              />
            );
          }}
        />
      )}
      <Pagination
        key={activeTab}
        data={
          tabs[activeTab].id === "NOT_STARTED"
            ? pendingTasks
            : tabs[activeTab].id === "IN_PROGRESS"
            ? inProgressTasks
            : tabs[activeTab].id === "PENDING_REVIEW"
            ? reviewTasks
            : completedTasks
        }
        totalCount={
          tabs[activeTab].id === "NOT_STARTED"
            ? taskCount?.pendingTaskCount
            : tabs[activeTab].id === "IN_PROGRESS"
            ? taskCount?.inProgressTaskCount
            : tabs[activeTab].id === "PENDING_REVIEW"
            ? taskCount?.reviewTaskCount
            : taskCount?.completedTaskCount
        }
        onPaginzationChange={onPaginzationChange}
        setDataLimit={setDataLimit}
        dataLimit={dataLimit}
      />
    </div>
  );
};

export default React.memo(AssignedTasks);
