import { _GET, _POST } from "../../../services/axios.methods";
import { API_ROUTE } from "../../../utils/api";
import {
  _getQueryState,
  _useMutation,
  _useMutationV2,
} from "../../../services/useAxiosQuery";
import { RQ_KEYS } from "../../../utils/constants";

const getOrganizationDetailsApi = async () => {
  const data = await _GET(`${API_ROUTE.GET_USER_ORGANIZATION}`);
  return data;
};

export const useManageOrganization = () => {
  const organizationData: any = _getQueryState({
    key: RQ_KEYS.USER_ORGANIZATION,
  });
  const { mutate: getOrganizationDetails } = _useMutationV2({
    apiCall: getOrganizationDetailsApi,
    onSuccess: () => {},
    queryKey: RQ_KEYS.USER_ORGANIZATION,
  });

  return { getOrganizationDetails, organizationData };
};
