import React, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import { Breadcrumb } from "../../../../common/Parts";
import styles from "./style.module.scss";
import {
  Button,
  MultiSelect,
  SearchBar,
  Table,
} from "../../../../common/components";
import {
  APP_ROLES,
  ASSESSMENT_MODAL_NAMES,
  AWS_DOCUMENT_BASE,
  LIMIT,
  REQUEST_TYPE,
  RQ_KEYS,
} from "../../../../utils/constants";
import { Dropdown } from "../../../../common/components";
import { ReactComponent as DownloadIcon } from "../../../../assests/svg/download.svg";
import { ReactComponent as DownIcon } from "../../../../assests/svg/down_arrow.svg";
import { ReactComponent as UpIcon } from "../../../../assests/svg/up_arrow.svg";
import { ReactComponent as DeleteIcon } from "../../../../assests/svg/delete_icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assests/svg/upload_icons.svg";
import { Spinner } from "react-bootstrap";
import {
  _getQueryState,
  _useMutation,
  useApiQuery,
} from "../../../../services/useAxiosQuery";
import { API_ROUTE } from "../../../../utils/api";
import { useQueryClient } from "react-query";
import Toast from "../../../../common/components/Toast";
import { ITOAST_STATE } from "../../../../interfaces/IToastState";
import { IModal } from "../../../../interfaces/IModal";
import DeleteTemplate from "../../../../common/Parts/ModalPopups/AssessmentModals/DeleteTemplate";
import UploadDocument from "../../../../common/Parts/ModalPopups/UploadDocument";
import useDebounce from "../../../../hooks/useDebounce";
import { localeDate } from "../../../../utils/helpers";
import DeleteDocument from "../../../../common/Parts/ModalPopups/AssessmentModals/DeleteDocumentModal";
import { Pagination } from "../../../../common/components/Pagination";
import Tooltip from "../../../../common/Parts/ToolTip";

// const LIMIT = 10;

const Documents = () => {
  const countRef = useRef(0);
  const [showDocument, setShowDocument] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState<string>("null");
  const [templateType, setTemplateType] = React.useState<string>("all");
  const [templateCategory, setTemplateCategory] = React.useState<string>("all");
  const [selectedRegion, setSelectedRegion] = React.useState<string>("all");
  const [showOrgDoc, setShowOrgDoc] = React.useState<boolean>(true);
  const [showMyDoc, setShowMyDoc] = React.useState<boolean>(true);
  const [showDeleteModal, setDeleteModal] = React.useState<IModal>({
    state: false,
    modalName: "",
    data: null,
    type: "DELETE",
  });

  const [myDocDataLimit, setMyDocDataLimit] = useState<any>({
    limit: LIMIT,
    offset: 0,
  });

  const [orgDocDataLimit, setOrgDocDataLimit] = useState<any>({
    limit: LIMIT,
    offset: 0,
  });

  const loadingState = _getQueryState({ key: RQ_KEYS.LOADING });
  const toastState: ITOAST_STATE | any = _getQueryState({
    key: RQ_KEYS.TOAST_STATE,
  });

  const userData: any = _getQueryState({ key: RQ_KEYS.USER_DATA });

  useEffect(() => {
    countRef.current++;
  }, []);

  const queryClient = useQueryClient();

  let templates: any = useApiQuery({
    queryKey: RQ_KEYS.DOCUMENT_LIB,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_DOCUMENTS}?search=${searchKey}&type=all&category=all&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
  });

  console.log("templates>>>>>>>", templates);
  const { data: assessmentTypes } = useApiQuery({
    queryKey: RQ_KEYS.ASSESSMENT_TYPES,
    requestType: REQUEST_TYPE._GET,
    url: API_ROUTE.GET_ASSESSMENT_TYPES,
  });

  const assessmentTypesList = assessmentTypes?.map(
    (type: { assessment_type: string }) => ({
      name: type.assessment_type,
      value: type.assessment_type,
    })
  );

  console.log("templates", templates);

  let { data: orgDocuments, isLoading: orgDocumentsIsLoading }: any =
    useApiQuery({
      queryKey: RQ_KEYS.ORG_DOCUMENTS,
      requestType: REQUEST_TYPE._GET,
      url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=all&category=all&region=${selectedRegion}&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
    });

  let { data: regions, isLoading: regionsLoading }: any = useApiQuery({
    queryKey: RQ_KEYS.REGIONS,
    requestType: REQUEST_TYPE._GET,
    url: `${API_ROUTE.GET_REGIONS}`,
  });

  console.log("regions", regions, regionsLoading);
  console.log("orgDocuments",orgDocuments);
  const orgDocumentsCount = orgDocuments?.totalCount;

  regions = regions &&
    regions?.length > 0 && [
      { name: "All Regions", value: "all", label: "All Regions" },
      ...regions,
    ];

  const getTemplates = _useMutation({ queryKey: RQ_KEYS.DOCUMENT_LIB });

  const getOrgDocuments = _useMutation({ queryKey: RQ_KEYS.ORG_DOCUMENTS });

  const searchQuery = useDebounce(searchKey, 300);

  React.useMemo(() => {
    if (countRef.current === 0) {
      return;
    }
    if (searchQuery || searchKey?.length < 0) {
      getTemplates.mutate({
        url: `${API_ROUTE.GET_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getOrgDocuments.mutate({
        url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&region=${selectedRegion}&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    } else if (searchKey?.length === 0) {
      getTemplates.mutate({
        url: `${API_ROUTE.GET_DOCUMENTS}?search=null&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${myDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
      getOrgDocuments.mutate({
        url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=null&type=${templateType}&category=${templateCategory}&region=${selectedRegion}&limit=${LIMIT}&offset=${orgDocDataLimit.offset}`,
        requestType: REQUEST_TYPE._GET,
      });
    }
  }, [searchQuery, templateCategory, templateType, selectedRegion]);

  const data =
    templates?.data?.data?.length > 0
      ? templates?.data?.data?.map(
          ({
            file_original_name,
            file_created_at,
            file_signed_url,
            document_id,
            document_type,
            document_category,
            uploaded_by,
            // region
          }: any) => {
            return {
              file_original_name,
              document_type,
              document_category,
              // file_created_at: localeDate(file_created_at),
              uploaded_by,
              file_created_at: localeDate(file_created_at),
              // region,
              file_signed_url,
              document_id,
            };
          }
        )
      : [];
// console.log(data,"dataa>>>>>>")
  orgDocuments =
    orgDocuments?.data?.length > 0
      ? orgDocuments?.data?.map(
          ({
            file_original_name,
            file_created_at,
            file_signed_url,
            document_id,
            document_type,
            document_category,
            uploaded_by,
            region,
          }: any) => {
            return {
              file_original_name,
              document_type,
              document_category,
              // file_created_at: localeDate(file_created_at),
              uploaded_by,
              file_created_at: localeDate(file_created_at),
              region,
              file_signed_url,
              document_id,
            };
          }
        )
      : [];

  const onUploadDeleteSuccess = (data: any) => {
    queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
      message: data && data.message,
      toastType: "SUCCESS",
    });
    queryClient.refetchQueries({ queryKey: RQ_KEYS.DOCUMENT_LIB }).then();
    queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_DOCUMENTS }).then();
  };

  const handleDeleteImage = (document_id: any) => {
    console.log("document Id in delete", document_id);
    setDeleteModal({
      state: true,
      modalName: ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT,
      type: "DELETE",
      data: document_id,
    });
  };

  const { mutate: uploadImage, isLoading: uploadImageLoading } = _useMutation({
    onSuccess: (data: any) => {
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: data && data.message,
        toastType: "SUCCESS",
      });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_DOCUMENTS }).then();
      queryClient
        .refetchQueries({ queryKey: RQ_KEYS.DOCUMENT_LIB })
        .then((data: any) => {
          setShowDocument(false);
        });
    },
    queryKey: RQ_KEYS.UPLOAD_DOCUMENT,
  });

  const handleSubmitUploadTemplate = (values: any, { replace }: any) => {
    console.log("values in upload template", values);
    const { image, document_category, document_type, region } = values;
    let fd = new FormData();
    fd.append("image", image as any);
    fd.append("document_type", document_type);
    fd.append("document_category", document_category);
    fd.append("region", region);
    fd.append("isReplace", replace ? "true" : "false");
    uploadImage({
      url: `${API_ROUTE.UPLOAD_DOCUMENT}`,
      Headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
      requestType: REQUEST_TYPE._POST,
      requestData: fd,
    });
  };

  const onPaginzationChangeMyDoc = ({ offset }: any) => {
    setMyDocDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getTemplates.mutate({
      url: `${API_ROUTE.GET_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&limit=${LIMIT}&offset=${offset}`,
      requestType: REQUEST_TYPE._GET,
    });
  };

  const onPaginzationChangeOrgDoc = ({ offset }: any) => {
    setOrgDocDataLimit((prev: any) => ({
      ...prev,
      offset,
    }));
    getOrgDocuments.mutate({
      url: `${API_ROUTE.GET_ORG_DOCUMENTS}?search=${searchKey}&type=${templateType}&category=${templateCategory}&region=${selectedRegion}&limit=${LIMIT}&offset=${offset}`,
      requestType: REQUEST_TYPE._GET,
    });
  };
  console.log("Selected Region:", selectedRegion);

  return (
    <React.Fragment>
      <div
        className={`manageAssessmentContainer ${styles.manageAssessmentContainer}`}
      >
        <div className={styles.breadCrumb}>
          <Breadcrumb
            pageName="Documents"
            //  path={["Dashboard", "Documents"]}
          />
          {userData?.role === APP_ROLES.ORG_ADMIN && (
            <div className={styles.fileInput}>
              <Button
                Icon={<UploadIcon fill="#ffffff" />}
                label="Upload Document"
                id="addDocLibrary"
                onClick={() => setShowDocument(true)}
              />
            </div>
          )}
        </div>

        <div className={styles.manageAssessmentBody}>
          <div className={styles.assessmentRow}>
            <div className={styles.filtrSearchDiv}>
              <Dropdown
                label="Document Type"
                dropdownData={[
                  { name: "All Types", value: "all" },
                  {
                    name: "Policies",
                    value: "Policies",
                  },
                  {
                    name: "Procedures",
                    value: "Procedures",
                  },
                  {
                    name: "Diagrams",
                    value: "Diagrams",
                  },
                ]}
                onClick={(data) => {
                  setTemplateType(data.value);
                }}
              />

              <Dropdown
                label="Document Category"
                // dropdownData={assessmentTypesList}
                dropdownData={
                  assessmentTypesList && assessmentTypesList.length > 0
                    ? [{ name: "All Categories", value: "all" }, ...assessmentTypesList]
                    : [{ name: "All Categories", value: "all" }]
                }
                onClick={(data) => {
                  console.log("data in filter", data);
                  setTemplateCategory(data.value);
                }}
              />
              <Dropdown
                label="Region"
                dropdownData={
                  regions &&
                  regions?.length > 0 &&
                  regions.map((region: any) => ({
                    name: region.label,
                    label: region.label,
                    value: region.value,
                  }))
                }
                onClick={(data) => {
                  console.log("data in filter", data);
                  setSelectedRegion(data.value);
                }}
              />
            </div>
            <div className={styles.searchContainer}>
              <SearchBar
                type="text"
                placeholder="Search"
                isRequired={false}
                name="searchBar"
                display="flex"
                onChange={(event: any) => setSearchKey(event.target.value)}
              />
            </div>
          </div>

          {userData?.role !== APP_ROLES.ORG_ADMIN && (
            <>
              <div
                className={styles.assessmentheader}
                onClick={() => setShowMyDoc(!showMyDoc)}
              >
                <h4>My Documents</h4>
                {showMyDoc ? (
                  <span style={{ width: "fit-content", padding: 0 }}>
                    <UpIcon
                      fill="black"
                      width={15}
                      height={15}
                      style={{ maxWidth: "20px" }}
                    />
                  </span>
                ) : (
                  <span style={{ width: "fit-content", padding: 0 }}>
                    <DownIcon
                      fill="black"
                      width={15}
                      height={15}
                      style={{ maxWidth: "15px" }}
                    />
                  </span>
                )}
              </div>
              {showMyDoc && (
                <div className={styles.assessmentMain}>
                  {!loadingState && !templates.isLoading ? (
                    <>
                      <Table
                        tableHeaders={[
                          "Document",
                          "Type",
                          "Category",
                          "Uploaded by",
                          "Date Uploaded",
                          // "Region",
                        ]}
                        tableData={data}
                        hideColumns={[
                          "document_id",
                          "file_signed_url",
                          "file_type",
                        ]}
                        // showActionWithColumns={[]}
                        action={{
                          name: "Action",
                          // buttonOne: (data: any) => {
                          //   return (
                          //     <ViewIcon
                          //       fill="#000000"
                          //       className="svg_image_icon"
                          //       onClick={() => {
                          //         // navigate(
                          //         //   `${APP_PATHS.CHAPTER}/${data?.assessment_id}`
                          //         // );
                          //       }}
                          //     />
                          //   );
                          // },
                          buttonOne: (data: any) => {
                            return (
                              <a
                                href={`${AWS_DOCUMENT_BASE}${data.file_signed_url}`}
                                download={`${data.file_original_name}`}
                              >
                                <Tooltip text="Download" rightSide={true}>
                                  <DownloadIcon
                                    fill="#000000"
                                    className="svg_image_icon"

                                    // // href={`${AWS_DOCUMENT_BASE}${data.file_signed_url}`}

                                    // onClick={async() => {
                                    //   console.log("data in button one", data);
                                    //   const fileData = await axios.get(`${AWS_DOCUMENT_BASE}${data.file_signed_url}`).then(
                                    //     res => res.data
                                    //   )
                                    //   console.log("File>>>>>>>>>", `${AWS_DOCUMENT_BASE}${data.file_signed_url}`);
                                    //   fileDownload(
                                    //     fileData,
                                    //   data.file_original_name
                                    //   );
                                    // //   // navigate(
                                    // //   //   `${APP_PATHS.CHAPTER}/${data?.assessment_id}`
                                    // //   // );
                                    // }}
                                  />
                                </Tooltip>
                              </a>
                            );
                          },
                          buttonTwo:
                            userData && userData.role === APP_ROLES.ORG_ADMIN
                              ? (data: any) => {
                                  console.log("data in button two", data);
                                  return (
                                    <DeleteIcon
                                      fill="#000000"
                                      className="svg_image_icon"
                                      onClick={() =>
                                        handleDeleteImage(data.document_id)
                                      }
                                    />
                                  );
                                }
                              : null,
                        }}
                      />
                      <Pagination
                        data={templates?.data?.data}
                        totalCount={templates?.data?.totalCount}
                        onPaginzationChange={onPaginzationChangeMyDoc}
                        setDataLimit={setMyDocDataLimit}
                        dataLimit={myDocDataLimit}
                      />
                    </>
                  ) : (
                    <div className={styles.assesmentLoader}>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                  {!loadingState &&
                    !templates.isLoading &&
                    data?.length === 0 && (
                      <div style={{ margin: "10px", textAlign: "center" }}>
                        No Documents Found
                      </div>
                    )}
                </div>
              )}
            </>
          )}

          {(userData?.role === APP_ROLES.ORG_USER ||
            userData?.role === APP_ROLES.BRANCH_ADMIN ||
            userData?.role === APP_ROLES.AUDITOR||
            userData?.role === APP_ROLES.ORG_ADMIN) && (
            <>
              <div
                className={styles.assessmentheader}
                onClick={() => setShowOrgDoc(!showOrgDoc)}
              >
                <h4>Organization Documents</h4>
                {showOrgDoc ? (
                  <span style={{ width: "fit-content", padding: 0 }}>
                    <UpIcon
                      fill="black"
                      width={15}
                      height={15}
                      style={{ maxWidth: "20px" }}
                    />
                  </span>
                ) : (
                  <span style={{ width: "fit-content", padding: 0 }}>
                    <DownIcon
                      fill="black"
                      width={15}
                      height={15}
                      style={{ maxWidth: "15px" }}
                    />
                  </span>
                )}
              </div>
              {showOrgDoc && (
                <div className={styles.assessmentMain}>
                  {!loadingState && !orgDocumentsIsLoading ? (
                    <>
                      <Table
                        tableHeaders={[
                          "Document",
                          "Type",
                          "Category",
                          "Uploaded by",
                          "Date Uploaded",
                          "Region",
                        ]}
                        tableData={orgDocuments}
                        hideColumns={[
                          "document_id",
                          "file_signed_url",
                          "file_type",
                        ]}
                        // showActionWithColumns={[]}
                        action={{
                          name: "Action",
                          // buttonOne: (data: any) => {
                          //   return (
                          //     <ViewIcon
                          //       fill="#000000"
                          //       className="svg_image_icon"
                          //       onClick={() => {
                          //         // navigate(
                          //         //   `${APP_PATHS.CHAPTER}/${data?.assessment_id}`
                          //         // );
                          //       }}
                          //     />
                          //   );
                          // },
                          buttonOne: (data: any) => {
                            return (
                              <a href={`${AWS_DOCUMENT_BASE}${data.file_signed_url}`} download="data.file_original_name.pdf">
                                <DownloadIcon
                                  fill="#000000"
                                  className="svg_image_icon"
                                  // style={{backgroundColor :"red"}}
                                  // // href={`${AWS_DOCUMENT_BASE}${data.file_signed_url}`}

                                  // onClick={() =>
                                  //   {
                                  //   console.log("data in button one", data);
                                  //   console.log("File>>>>>>>>>", `${AWS_DOCUMENT_BASE}${data.file_signed_url}`);
                                  //   fileDownload(
                                  //     `${AWS_DOCUMENT_BASE}${data.file_signed_url}`,
                                  //   "data.txt"
                                  //   );
                                  // // //   // navigate(
                                  // // //   //   `${APP_PATHS.CHAPTER}/${data?.assessment_id}`
                                  // // //   // );
                                  // }}
                                />
                              </a>
                            );
                          },
                          buttonTwo:
                            userData && userData.role === APP_ROLES.ORG_ADMIN
                              ? (data: any) => {
                                  console.log("data in button two", data);
                                  return (
                                    <DeleteIcon
                                      fill="#000000"
                                      className="svg_image_icon"
                                      onClick={() =>
                                        handleDeleteImage(data.document_id)
                                      }
                                    />
                                  );
                                }
                              : null,
                        }}
                      />
                      <Pagination
                        data={orgDocuments}
                        totalCount={orgDocumentsCount}
                        onPaginzationChange={onPaginzationChangeOrgDoc}
                        setDataLimit={setOrgDocDataLimit}
                        dataLimit={orgDocDataLimit}
                      />
                    </>
                  ) : (
                    <div className={styles.assesmentLoader}>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                  {!loadingState &&
                    !orgDocumentsIsLoading &&
                    orgDocuments?.length === 0 && (
                      <div style={{ margin: "30px", textAlign: "center" }}>
                        No Documents Found
                      </div>
                    )}
                </div>
              )}
            </>
          )}
        </div>
        {/* {showDocument && (
          <UploadFile onClickCancel={() => setShowDocument(false)} />
        )} */}
        {toastState &&
          toastState.message &&
          !toastState.message.includes("REPLACE") && (
            <Toast toastState={toastState} />
          )}
      </div>
      {showDeleteModal &&
        showDeleteModal.state &&
        showDeleteModal.modalName ===
          ASSESSMENT_MODAL_NAMES.DELETE_DOCUMENT && (
          <DeleteDocument
            openState={showDeleteModal}
            heading="Delete Document"
            type={showDeleteModal?.type}
            data={showDeleteModal.data}
            onClickCancel={() => {
              setDeleteModal((prev) => ({ ...prev, state: false }));
            }}
            onSuccess={onUploadDeleteSuccess}
          />
        )}
      <UploadDocument
        openState={showDocument}
        heading="Upload Document"
        Title="Document"
        onClickCancel={() => {
          queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
            message: "",
            toastType: "",
          });
          setShowDocument(false);
        }}
        handleSubmitUploadTemplate={handleSubmitUploadTemplate}
        uploadImageLoading={uploadImageLoading}
        region={
          regions &&
          regions?.length > 0 &&
          regions?.filter((region: any) => region.label !== "All Regions")
        }
        userRole={userData?.role}
      />
    </React.Fragment>
  );
};

export default Documents;
