import React from "react";
import styles from "./style.module.scss";
import { Button } from "../../../components";
import { ReactComponent as SaveIcon } from "../../../../assests/svg/save_close.svg";
import { ReactComponent as CancelBtn } from "../../../../assests/svg/close.svg";
import { ISubmitAssessmentModal } from "../../../../interfaces/ILocation";
import { Modal } from "@mui/material";
import { useQueryClient } from "react-query";
import {
    REQUEST_TYPE,
    RQ_KEYS,
  } from "../../../../utils/constants";
import { API_ROUTE } from "../../../../utils/api";
import {
    _getQueryState,
    _useMutation,
  } from "../../../../services/useAxiosQuery";

const SubmitAssessmentModal: React.FC<ISubmitAssessmentModal> = ({
  onClickCancel,
  openState,
  data, 
  onSuccess 
//   onSubmit
}) => {
  const queryClient = useQueryClient();  

  const { mutate: submitAssessment, isLoading: submitAssessmentLoading } = _useMutation({
    queryKey: RQ_KEYS.SUBMIT_ASSESSMENT,

    onSuccess: ({ message }: any) => {
      queryClient.setQueryData(RQ_KEYS.TOAST_STATE, {
        message: message,
        toastType: "SUCCESS",
      });

      queryClient.refetchQueries({ queryKey: RQ_KEYS.ALL_CHAPTERS });
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ASSESSMENT_BY_IDS });
      onClickCancel();
    },
  })
  const handleSubmitAssessment = () => {
    console.log("data in Mark as Complete Assessment Modal", data);
    submitAssessment({
      url: API_ROUTE.SUBMIT_ASSESSMENT,
      requestType: REQUEST_TYPE._PATCH,
      requestData: {
        assessment_id: data?.assessment_id,
        assessment_name: data?.assessment_name,
      },
    });
    // onClickCancel();
  };

  return (
    <Modal
      open={openState}
      onClose={onClickCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.invitePopupContainer}>
        <div className={styles.invitePopupContainerInner}>
          <div className={styles.addChapterHeader}>
            <p>Are you sure?</p>
          </div>

          <div className={styles.manageWarning}>
            <p>This will complete the assessment and you will no longer have edit access. Do you want to continue?</p>

          </div>
          <div className={styles.inviteUserBtn}>
            <Button
              label="Cancel"
              id="cancelbtn"
              type="button"
              borderRadius="5px"
              fontWeight="bold"
              fontSize="var(--secondaryFont)"
              background="var(--whiteColor)"
              border="1px solid var(--secondaryColor)"
              color="var(--secondaryColor)"
              Icon={
                <CancelBtn fill="#0097ce" style={{ maxWidth: "12px" }} />
              }
              onClick={onClickCancel}
            />
            <Button
              label="Yes, Mark as Completed"
              id="Submit"
              borderRadius="5px"
              fontWeight="bold"
              color="var(--whiteColor)"
              fontSize="var(--secondaryFont)"
              border="none"
              disabled={submitAssessmentLoading}
              onClick={() => {
                handleSubmitAssessment()

                // onClickCancel();
              }}
              type={"button"}
              Icon={<SaveIcon fill="#ffffff" className="svg_image_icon" />}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitAssessmentModal;