import { _GET, _PATCH, _POST } from "../../../services/axios.methods";
import { API_ROUTE } from "../../../utils/api";
import {
  _getQueryState,
  _useMutation,
  _useMutationV2,
} from "../../../services/useAxiosQuery";
import { RQ_KEYS } from "../../../utils/constants";
import { useQueryClient } from "react-query";

const getOrgAdminListApi = async ({ params }: any) => {
  const data = await _GET(`${API_ROUTE.GET_ORG_ADMINS_LIST}` + params);
  console.log("<<< getOrgAdminListApi <<<", data);
  return data;
};
const resentInvitation = async ( payload : any) => {
  const data = await _POST(`${API_ROUTE.RESEND_ORG_ADMIN_INVITE}`, payload);
  return data;
};
const changeUserActiveStatus = async (payload: any) => {
  console.log("changeUserActiveStatus", payload);
  const data = await _PATCH(`${API_ROUTE.ACTIVE_SUSPEND_ORGNIZATION}`, payload);
  return data;
};
export const useManageOrganization = () => {
  const queryClient = useQueryClient();
  const orgAdminLists = _getQueryState({ key: RQ_KEYS.ORG_ADMINS_LIST });

  let getAdminListLoadingState = _getQueryState({
    key: `${RQ_KEYS.ORG_ADMINS_LIST}_loading`,
  });

  const { mutate: getOrgAdminLists } = _useMutationV2({
    apiCall: getOrgAdminListApi,
    onSuccess: () => {
      getAdminListLoadingState = queryClient.setQueryData(`${RQ_KEYS.ORG_ADMINS_LIST}_loading`, false);
      // queryClient.invalidateQueries({ queryKey: RQ_KEYS.ORG_ADMINS_LIST });
    },
    onError: () => {
      getAdminListLoadingState = queryClient.setQueryData(`${RQ_KEYS.ORG_ADMINS_LIST}_loading`, false);
    },
    queryKey: RQ_KEYS.ORG_ADMINS_LIST,
  });
  const { mutate: resentInvitationApi } = _useMutationV2({
    apiCall: resentInvitation,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_ADMINS_LIST });
    },
  });
  const { mutate: changeUserActiveStatusApi } = _useMutationV2({
    apiCall: changeUserActiveStatus,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: RQ_KEYS.ORG_ADMINS_LIST });
    },
  });

  console.log("<<< orgAdminLists <<<", orgAdminLists);

  return {
    getOrgAdminLists,
    resentInvitationApi,
    changeUserActiveStatusApi,
    orgAdminLists,
    getAdminListLoadingState,
  };
};
