import { _getQueryState } from "../services/useAxiosQuery";
import { APP_PATHS } from "./constants";

export const breadcrumbMapping = {
  [APP_PATHS.LOGIN]: "Login",
  [APP_PATHS.FORGET_PASSWORD]: "Forgot Password",
  [APP_PATHS.RESET_PASSWORD]: "Reset Password",
  [APP_PATHS.FORGET_PASSWORD_STATUS]: "Forgot Password Status",
  [APP_PATHS.RESET_PASSWORD_STATUS]: "Reset Password Status",
  [APP_PATHS.RESET_AND_UPDATE_DETAILS]: "Reset and Update Details",
  [APP_PATHS.ORG_ADMIN_MANAGEMENT]: "Administration > Organizations",
  [APP_PATHS.GENERATE_REPORTS]: "Reports > Generate Reports",
  [APP_PATHS.HISTORICAL_REPORTS]: "Reports > Historical Reports",
  [APP_PATHS.EDIT_ORGANIZATION]: "Edit Organization",
  [APP_PATHS.UN_AUTHENTICATED]: "Unauthorized Access",
  [APP_PATHS.ASSESSMENTS]: "Assessments > Assessments List",
  [APP_PATHS.ORG_ONBOARDING]: "Administration > Organization Onboarding",
  [APP_PATHS.CHAPTER]: "Chapters",
  [APP_PATHS.ASSESSMENT_LIST]: "Assessments > Assessment List",
  [APP_PATHS.ASSESSMENT_CHAPTER_LIST]: "Chapter List",
  [APP_PATHS.MANAGE_ORGANIZATION]: "Manage > Organization",
  [APP_PATHS.ORG_DASHBOARD]: "Dashboard > Dashboard",
  [APP_PATHS.ORG_DASHBOARD_LOCATIONS]:
    "Dashboard > Dashboard > Organization Locations",
  [APP_PATHS.ORG_DASHBOARD_BRANCH]: "Organization Dashboard > Branch Status",
  [APP_PATHS.ORG_EXECUTIVE]: "Executive Dashboard",
  [APP_PATHS.VIEW_ORGANIZATION]: "View Organization",
  [APP_PATHS.VIEW_USER]: "View User",
  [APP_PATHS.VIEW_VENDOR]: "View Vendor",
  [APP_PATHS.QUESTION_SCREEN]:
    "Assessments > Assessments List > Chapter List > Questionnaire",
  [APP_PATHS.ASSIGNED_TASKS]: "Tasks > My Tasks",
  [APP_PATHS.MANAGE_USERS]: "Manage > Users",
  [APP_PATHS.MANAGE_VENDORS]: "Manage > Vendors",
  [APP_PATHS.VENDOR_DASHBOARD]: "Vendor Dashboard",
  [APP_PATHS.ASSESSMENT_DASHBOARD]: "Assessments > Assessments List",
  [APP_PATHS.USER_ASSESSMENT]: "User Assessments",
  [APP_PATHS.DOCUMENTS]: "Document Library > Documents",
  [APP_PATHS.VENDOR_DOCUMENTS]: "Document Library > Vendor Documents",
  [APP_PATHS.TEMPLATE_SECTION]: "Template Section",
  [APP_PATHS.TEMPLATE]: "Document Library > Templates",
  [APP_PATHS.SUCCESS]: "Success",
  [APP_PATHS.ASSIGNED_ASSESSMENTS]: "Assigned Assessments",
  [APP_PATHS.ORGADMIN_LIST]: "Organization Admin List",
  [APP_PATHS.ORGUSER_LIST]: "Organization User List",
  [APP_PATHS.BRANCHADMIN_LIST]: "Branch Admin List",
  [APP_PATHS.VENDORUSER_LIST]: "Vendor User List",
  [APP_PATHS.TRACK_TASKS]: "Tasks > Assigned Tasks",
  [APP_PATHS.USER_LOGIN_DETAILS]: "User Details",
  [APP_PATHS.SYSTEM_TEMPLATES]: "Document Library > Templates",
  [APP_PATHS.QUESTIONAIRE_SUCCESS]: "Assessment > Questionnaire Success",
};

export const childBreadcrumbMapping = {
  [APP_PATHS.LOGIN]: "Login",
  [APP_PATHS.FORGET_PASSWORD]: "Forgot Password",
  [APP_PATHS.RESET_PASSWORD]: "Reset Password",
  [APP_PATHS.FORGET_PASSWORD_STATUS]: "Forgot Password Status",
  [APP_PATHS.RESET_PASSWORD_STATUS]: "Reset Password Status",
  [APP_PATHS.RESET_AND_UPDATE_DETAILS]: "Reset and Update Details",
  [APP_PATHS.ORG_ADMIN_MANAGEMENT]: "Manage > Users",
  [APP_PATHS.GENERATE_REPORTS]: "Reports > Generate Reports",
  [APP_PATHS.HISTORICAL_REPORTS]: "Reports > Historical Reports",
  [APP_PATHS.EDIT_ORGANIZATION]: "Edit Organization",
  [APP_PATHS.UN_AUTHENTICATED]: "Unauthorized Access",
  [APP_PATHS.ASSESSMENTS]: "My To Do > My Assessments",
  [APP_PATHS.ORG_ONBOARDING]: "Administration > Organization Onboarding",
  [APP_PATHS.CHAPTER]: "Chapters",
  [APP_PATHS.ASSESSMENT_LIST]: "Assessments > Assessment List",
  [APP_PATHS.ASSESSMENT_CHAPTER_LIST]: "Chapter List",
  [APP_PATHS.MANAGE_ORGANIZATION]: "Manage > Organization",
  [APP_PATHS.ORG_DASHBOARD]: "Dashboard > Dashboard",
  [APP_PATHS.ORG_DASHBOARD_LOCATIONS]:
    "Dashboard > Dashboard > Organization Locations",
  [APP_PATHS.ORG_DASHBOARD_BRANCH]: "Organization Dashboard > Branch Status",
  [APP_PATHS.ORG_EXECUTIVE]: "Dashboard > Dashboard",
  [APP_PATHS.VIEW_ORGANIZATION]: "View Organization",
  [APP_PATHS.VIEW_USER]: "View User",
  [APP_PATHS.VIEW_VENDOR]: "View Vendor",
  [APP_PATHS.QUESTION_SCREEN]: "My To Do > My Assessments > Questionnaire",
  [APP_PATHS.ASSIGNED_TASKS]: "My To Do > My Tasks",
  [APP_PATHS.MANAGE_USERS]: "Manage > Vendors",
  [APP_PATHS.MANAGE_VENDORS]: "Manage > Vendors",
  [APP_PATHS.VENDOR_DASHBOARD]: "Vendor Dashboard",
  [APP_PATHS.ASSESSMENT_DASHBOARD]: "My To Do > My Assessments",
  [APP_PATHS.USER_ASSESSMENT]: "User Assessments",
  [APP_PATHS.DOCUMENTS]: "Document Library > Documents",
  [APP_PATHS.VENDOR_DOCUMENTS]: "Document Library > Documents",
  [APP_PATHS.TEMPLATE_SECTION]: "Template Section",
  [APP_PATHS.TEMPLATE]: "Document Library > Templates",
  [APP_PATHS.SUCCESS]: "Success",
  [APP_PATHS.ASSIGNED_ASSESSMENTS]: "Assigned Assessments",
  [APP_PATHS.ORGADMIN_LIST]: "Organization Admin List",
  [APP_PATHS.ORGUSER_LIST]: "Organization User List",
  [APP_PATHS.BRANCHADMIN_LIST]: "Branch Admin List",
  [APP_PATHS.VENDORUSER_LIST]: "Vendor User List",
  [APP_PATHS.TRACK_TASKS]: "Assigned Tasks > Tasks ",
  [APP_PATHS.USER_LOGIN_DETAILS]: "User Details",
  [APP_PATHS.SYSTEM_TEMPLATES]: "Document Library > Templates",
  [APP_PATHS.QUESTIONAIRE_SUCCESS]: "Assessment > Questionnaire Success",
};
